import { JsonObjectType, optional, typeDefiner } from '2mr-cms-typegen';
import {
  HasTitleAndUrlAndTarget,
  ImageCardContent,
  OptionalLinkCardContent,
} from './Common';

const defineType = typeDefiner('Promo.ts');

const PromoHeaderContent: JsonObjectType = {
  backgroundVideo: {
    video: 'image',
    cover: 'image',
  },
  preHeader: 'string',
  header: 'string',
  button: HasTitleAndUrlAndTarget,
  ytLink: { ...HasTitleAndUrlAndTarget, icon: 'image' },
};
defineType(PromoHeaderContent, 'PromoHeaderContent');

const TicketCategory: JsonObjectType = {
  title: 'string',
  price: 'number',
  button: { ...HasTitleAndUrlAndTarget, disabled: 'boolean' },
};
defineType(TicketCategory, 'PromoTicketCategoryContent');

const TicketContent: JsonObjectType = {
  badge: {
    title: 'string',
    background: 'color',
  },
  title: 'string',
  list: ['string'],
  categories: [TicketCategory],
};
defineType(TicketContent, 'PromoTicketContent');

const TicketsContent: JsonObjectType = {
  id: 'string',
  title: 'string',
  description: 'richText',
  tickets: [TicketContent],
};
defineType(TicketsContent, 'PromoTicketsContent');

const ObstaclesContent: JsonObjectType = {
  id: 'string',
  bgImage: 'image',
  headerTitle: 'string',
  headerDescription: 'richText',
};
defineType(ObstaclesContent, 'PromoObstaclesContent');

const AfterPartyContent: JsonObjectType = {
  id: 'string',
  bgImage: 'image',
  headerTitle: 'string',
  headerDescription: 'richText',
};
defineType(AfterPartyContent, 'PromoAfterPartyContent');

const HistorySlide: JsonObjectType = {
  logo: 'image',
  title: 'string',
  survivorsNumber: optional('number'),
  description: 'richText',
  image: 'image',
};
defineType(HistorySlide, 'PromoHistorySlideContent');

const HistoryContent: JsonObjectType = {
  title: 'string',
  description: 'richText',
  slides: [HistorySlide],
};
defineType(HistoryContent, 'PromoHistory');

const ContactUsContent: JsonObjectType = {
  id: 'string',
  image: 'image',
};
defineType(ContactUsContent, 'PromoContactUsContent');

const ComradesContent: JsonObjectType = {
  id: 'string',
};
defineType(ComradesContent, 'PromoComradesContent');

const DecorativePhotosContent: JsonObjectType = {
  id: 'string',
};
defineType(DecorativePhotosContent, 'PromoDecorativePhotosContent');

const WhatIsContent: JsonObjectType = {
  ...OptionalLinkCardContent,
  id: 'string',
};
defineType(WhatIsContent, 'PromoWhatIsContent');

export const PromoContent: JsonObjectType = {
  promoHeader: PromoHeaderContent,
  whatIsRFYL: WhatIsContent,
  tickets: TicketsContent,
  obstacles: ObstaclesContent, //plus the rest from /event page
  afterParty: AfterPartyContent, //plus the rest from /event page
  historySlider: HistoryContent,
  decorativePhotos: DecorativePhotosContent, //plust PhotosDecorativeContent from /home page
  joinCommunity: ImageCardContent, // plus socials from footer
  comrades: ComradesContent, //plus ComradesContent from /home page
  //outbreakNews: ComradesContent,
  contactUs: ContactUsContent, //plus ContactFormBlockContent from /contact page
};
defineType(PromoContent, 'PromoContent');

export const importPromoContent = () => {};

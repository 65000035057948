import React, { useState } from 'react';
import styles from './style.scss';
import { VideoContent } from '../../../../generated/Home';
import { clsx, PoorText, RichText } from '2mr-cms-frontend';
import { Button } from '../../../inline/button/Button';

export const HomeVideo = ({
  subtitle,
  title,
  video,
  image1,
  image2,
  image3,
  videoThumb,
  button,
}: VideoContent) => {
  const [playing, setPlaying] = useState(false);
  const [videoVisible, setVideoVisible] = useState(false);
  const [videoRef, setVideoRef] = useState<HTMLVideoElement | null>(null);

  const play = () => {
    if (videoRef) {
      videoRef
        .play()
        .then(() => {
          setPlaying(true);
          setVideoVisible(true);
        })
        .catch((err) => console.log(err));
    }
  };

  const pause = () => {
    if (videoRef) {
      videoRef.pause();
      setPlaying(false);
    }
  };

  return (
    <div className={styles.homeVideo}>
      <PoorText content={subtitle} className={styles.subtitle} />
      <RichText content={title} className={styles.title} />
      <div className={styles.videoWrapper}>
        <img src={videoThumb.value} className={styles.videoThumbnail} />
        <video
          className={clsx(styles.video, videoVisible && styles.videoVisible)}
          preload='auto'
          controls={false}
          ref={(el) => setVideoRef(el)}
        >
          <source src={video.value} type='video/mp4' />
        </video>
        <VideoControls
          playing={playing}
          play={play}
          pause={pause}
          alwaysVisible={!videoVisible}
        />
      </div>
      <Button
        className={styles.button}
        style={'air'}
        color={'white'}
        href={button.url}
        targetBlank={button.targetBlank}
      >
        <PoorText content={button.title} />
      </Button>
    </div>
  );
};

interface ControlsProps {
  playing: boolean;
  play: () => void;
  pause: () => void;
  alwaysVisible: boolean;
}

const VideoControls = ({
  playing,
  play,
  pause,
  alwaysVisible,
}: ControlsProps) => (
  <div
    className={clsx(
      styles.videoControls,
      alwaysVisible && styles.videoControlsVisible
    )}
    onClick={playing ? pause : play}
  >
    <div className={styles.videoControl}>
      {playing ? <PauseSvg /> : <PlaySvg />}
    </div>
  </div>
);

const PlaySvg = () => (
  <svg
    width='42'
    height='47'
    viewBox='0 0 42 47'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    style={{ marginLeft: 5 }}
  >
    <path
      d='M39.2759 27.1556L6.61095 46.1088C3.83882 47.7156 0.28125 45.77 0.28125 42.5307V4.62433C0.28125 1.39017 3.83369 -0.560588 6.61095 1.05136L39.2759 20.0045C39.9065 20.3645 40.4307 20.8849 40.7953 21.5128C41.1599 22.1408 41.3519 22.854 41.3519 23.5801C41.3519 24.3062 41.1599 25.0194 40.7953 25.6473C40.4307 26.2753 39.9065 26.7956 39.2759 27.1556Z'
      fill='white'
    />
  </svg>
);

const PauseSvg = () => (
  <svg
    width='34'
    height='38'
    viewBox='0 0 34 38'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M6.6875 0.4375C8.32853 0.4375 9.90234 1.0894 11.0627 2.24978C12.2231 3.41016 12.875 4.98397 12.875 6.625V31.375C12.875 33.016 12.2231 34.5898 11.0627 35.7502C9.90234 36.9106 8.32853 37.5625 6.6875 37.5625C5.04647 37.5625 3.47266 36.9106 2.31228 35.7502C1.15189 34.5898 0.5 33.016 0.5 31.375V6.625C0.5 4.98397 1.15189 3.41016 2.31228 2.24978C3.47266 1.0894 5.04647 0.4375 6.6875 0.4375ZM27.3125 0.4375C28.9535 0.4375 30.5273 1.0894 31.6877 2.24978C32.8481 3.41016 33.5 4.98397 33.5 6.625V31.375C33.5 33.016 32.8481 34.5898 31.6877 35.7502C30.5273 36.9106 28.9535 37.5625 27.3125 37.5625C25.6715 37.5625 24.0977 36.9106 22.9373 35.7502C21.7769 34.5898 21.125 33.016 21.125 31.375V6.625C21.125 4.98397 21.7769 3.41016 22.9373 2.24978C24.0977 1.0894 25.6715 0.4375 27.3125 0.4375Z'
      fill='white'
    />
  </svg>
);

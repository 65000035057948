import { Route, Routes } from 'react-router-dom';
import React from 'react';
import {
  HOME_PAGE_URL,
  ABOUT_PAGE_URL,
  CHARACTERS_PAGE_URL,
  CONTACT_PAGE_URL,
  EVENT_PAGE_URL,
  NEWS_PAGE_URL,
  NEWS_SINGLE_URL,
  PRICING_PAGE_URL,
} from './data/Urls';
import { HomePage } from './page/Home';
import { AboutPage } from './page/About';
import { CharactersPage } from './page/Characters';
import { EventPage } from './page/Event';
import { NewsPage } from './page/News';
import { PricingPage } from './page/Pricing';
import { ContactPage } from './page/Contact';
import { NewsSinglePage } from './page/NewsSinglePage';
import { HomeContent } from './generated/Home';
import { AboutContent } from './generated/About';
import { CharactersContent } from './generated/Characters';
import { EventContent } from './generated/EventContent';
import { PricingContent } from './generated/PricingContent';
import { ContactContent } from './generated/ContactContent';
import { NewsHeaderContent } from './generated/NewsHeaderContent';
import { PromoContent } from './generated/Promo';
import { PromoPage, PromoPageAddons } from './page/Promo';
import { FooterContent } from './generated/Navigation';

export type RouterProps = {
  homeContent: HomeContent;
  promoContent: PromoContent;
  aboutContent: AboutContent;
  eventContent: EventContent;
  charactersContent: CharactersContent;
  newsHeaderContent: NewsHeaderContent;
  pricingContent: PricingContent;
  contactContent: ContactContent;
  footerContent: FooterContent;
  promo: boolean;
};

export const Router = ({
  homeContent,
  aboutContent,
  eventContent,
  charactersContent,
  newsHeaderContent,
  pricingContent,
  contactContent,
  promoContent,
  footerContent,
  promo,
}: RouterProps) => {
  const promoPageAddons: PromoPageAddons = {
    obstacles: eventContent.forSurvivor,
    afterParty: eventContent.forSpectaror,
    decorativePhotos: homeContent.decorativePhotos,
    joinCommunity: footerContent.socials,
    comrades: homeContent.comrades,
    outbreakNews: homeContent.outbreakNews,
    contactUs: contactContent.formContent,
  };

  if (promo) {
    return (
      <Routes>
        <Route
          path={'*'}
          element={<PromoPage promo={promoContent} addons={promoPageAddons} />}
        />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route
        path={HOME_PAGE_URL}
        element={<HomePage homeContent={homeContent} />}
      />
      <Route
        path={ABOUT_PAGE_URL}
        element={<AboutPage content={aboutContent} />}
      />
      <Route
        path={EVENT_PAGE_URL}
        element={<EventPage content={eventContent} />}
      />
      <Route
        path={CHARACTERS_PAGE_URL}
        element={<CharactersPage content={charactersContent} />}
      />
      <Route
        path={NEWS_PAGE_URL}
        element={<NewsPage content={newsHeaderContent} />}
      />
      <Route
        path={NEWS_SINGLE_URL}
        element={<NewsSinglePage content={newsHeaderContent} />}
      />
      <Route
        path={PRICING_PAGE_URL}
        element={<PricingPage content={pricingContent} />}
      />
      <Route
        path={CONTACT_PAGE_URL}
        element={<ContactPage content={contactContent} />}
      />

      <Route path={'*'} element={<HomePage homeContent={homeContent} />} />
    </Routes>
  );
};

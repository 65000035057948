import { HasTitleAndUrl, OptionalLinkCardContent } from './Common';
import { typeDefiner, JsonObjectType, oneOf, optional } from '2mr-cms-typegen';

const defineType = typeDefiner('ContactContent.ts');

export const FormFieldContent: JsonObjectType = {
  type: oneOf('text', 'email', 'tel', 'multiline text'),
  placeholder: optional('text'),
  title: 'text',
  required: 'boolean',
};
defineType(FormFieldContent, 'FormFieldContent');

export const ContactFormContent: JsonObjectType = {
  title: 'text',
  description: 'richText',
  fields: [FormFieldContent],
  requiredLabel: 'text',
  invalidFieldText: 'text',
  invalidIcon: 'image',
  submitButtonText: 'text',
  sendingButtonText: 'text',
};

defineType(ContactFormContent, 'ContactFormContent');

export const FormSuccessContent: JsonObjectType = {
  title: 'text',
  description: 'richText',
  button: HasTitleAndUrl,
};
defineType(FormSuccessContent, 'FormSuccessContent');

export const ContactFormBlockContent: JsonObjectType = {
  form: ContactFormContent,
  submitted: FormSuccessContent,
  backgroundUrl: 'image',
};

defineType(ContactFormBlockContent, 'ContactFormBlockContent');
export const ContactGetInvolvedContent: JsonObjectType = {
  title: 'text',
  description: optional('richText'),
  cards: [OptionalLinkCardContent],
};
defineType(ContactGetInvolvedContent, 'ContactGetInvolvedContent');

export const ContactContent: JsonObjectType = {
  formContent: ContactFormBlockContent,
  getInvolved: ContactGetInvolvedContent,
};

defineType(ContactContent, 'ContactContent');
export const importContactContent = () => {};

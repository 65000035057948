import { JsonObjectType, oneOf, optional, typeDefiner } from '2mr-cms-typegen';
import {
  HasTitleAndDescription,
  HasTitleAndUrlAndTarget,
  ImageCardContent,
  LinkImageContent,
  OptionalLinkCardContent,
  ThreeImageCardsContent,
} from './Common';

const defineType = typeDefiner('Home.ts');

export const ComradesContent: JsonObjectType = {
  ...HasTitleAndDescription,
  list: [LinkImageContent],
};
defineType(ComradesContent, 'ComradesContent');

export const HomeJoinCommunityContent: JsonObjectType = {
  title: 'text',
  description: 'richText',
  backgroundImageLeft: 'image',
  backgroundImageRight: 'image',
  button: {
    url: 'url',
    title: 'text',
    icon: optional('image'),
  },
};
defineType(HomeJoinCommunityContent, 'HomeJoinCommunityContent');

export const PhotosDecorativeContent: JsonObjectType = {
  rows: [
    {
      list: ['image'],
      animationSpeed: optional('number'),
    },
  ],
  rowGapCss: optional('text'),
  columnGapCss: optional('text'),
  gridTransformCss: optional('text'),
  imageBorderCss: optional('text'),
};
defineType(PhotosDecorativeContent, 'PhotosDecorativeContent');

export const HowToGetContent: JsonObjectType = {
  title: 'text',
  description: optional('richText'),
  image: 'image',
  steps: [ImageCardContent],
};
defineType(HowToGetContent, 'HowToGetContent');

export const CountdownContent: JsonObjectType = {
  eventDateTime: 'date',
  title: 'text',
  subtitle: 'text',
  tagText: 'text',
};
defineType(CountdownContent, 'CountdownContent');

const InfectedWorldwideContent: JsonObjectType = {
  title: 'text',
  description: 'richText',
  slides: [ImageCardContent],
};
defineType(InfectedWorldwideContent, 'InfectedWorldwideContent');

const HeaderSliderSlideContent: JsonObjectType = {
  ...HasTitleAndDescription,
  image: 'image',
  link: HasTitleAndUrlAndTarget,
  backgroundColor: 'color',
  backgroundGradientDirection: oneOf('left', 'right'),
  backgroundFit: oneOf('contain', 'cover'),
};
defineType(HeaderSliderSlideContent, 'HeaderSliderSlideContent');

const HeaderSliderContent: JsonObjectType = {
  speed: optional('number'),
  slides: [HeaderSliderSlideContent],
};
defineType(HeaderSliderContent, 'HeaderSliderContent');

const RecentNewsBlockContent: JsonObjectType = {
  title: optional('text'),
  buttonLink: {
    label: optional('text'),
    url: 'url',
  },
};
defineType(RecentNewsBlockContent, 'RecentNewsBlockContent');

const VideoContent: JsonObjectType = {
  subtitle: 'text',
  title: 'text',
  video: 'image',
  videoThumb: 'image',
  image1: 'image',
  image2: 'image',
  image3: 'image',
  button: HasTitleAndUrlAndTarget,
};
defineType(VideoContent, 'VideoContent');

export const HomeContent: JsonObjectType = {
  headerSlider: HeaderSliderContent,
  videoContent: VideoContent,
  countdown: CountdownContent,
  whatIs: OptionalLinkCardContent,
  ultimateExpirience: ThreeImageCardsContent,
  infectedWorldwide: InfectedWorldwideContent,
  decorativePhotos: PhotosDecorativeContent,
  howToGetTickets: HowToGetContent,
  entryPasses: ThreeImageCardsContent,
  joinCommunity: HomeJoinCommunityContent,
  comrades: ComradesContent,
  outbreakNews: ComradesContent,
  finalStand: OptionalLinkCardContent,
  recentNewsBlock: RecentNewsBlockContent,
};
defineType(HomeContent, 'HomeContent');
export const importHomeContent = () => {};

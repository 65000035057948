import styles from './style.scss';
import React from 'react';

type Props = {
  left: React.ReactNode;
  right: React.ReactNode;
};
export const SlicedBlock = (props: Props) => {
  return (
    <div className={styles.slicedBlock}>
      <div className={styles.container}>
        <div className={styles.left}>
          <div className={styles.bgLeft}></div>
          <div className={styles.itemLeft}>{props.left}</div>
        </div>
        <div className={styles.right}>
          <div className={styles.bgRight}></div>
          {props.right}
        </div>
      </div>
    </div>
  );
};

import styles from './style.scss';
import React from 'react';
import { PhotosDecorativeContent } from '../../../generated/Home';
import { ImageContent } from '../../../generated/StdLib';
import { Picture } from '2mr-cms-frontend';

const MIN_COLUMNS = 5;

function fillTo(arr: Array<any>, min: number) {
  if (arr.length >= min) {
    return arr;
  }
  let newArr = arr.slice();
  while (newArr.length < min) {
    newArr = newArr.concat(arr);
  }
  return newArr;
}

type Props = PhotosDecorativeContent & { id?: string };

export const PhotosDecorative = (props: Props) => {
  const style = {
    '--row-gap': props.rowGapCss || '20px',
    '--column-gap': props.columnGapCss || '12px',
    '--grid-transform': props.gridTransformCss || 'rotate(15deg) scale(1.5)',
    '--image-border': props.imageBorderCss || '',
  } as React.CSSProperties;

  const rrr = props.rows.map((item, i) => {
    return <PhotosDecorativeRow {...item} key={i} />;
  });
  return (
    <div className={styles.wrapper} id={props.id}>
      <div className={styles.container}>
        <div className={styles.grid} style={style}>
          {rrr}
        </div>
      </div>
    </div>
  );
};
type PropsRow = {
  list: ImageContent[];
  animationSpeed?: number;
};
export const PhotosDecorativeRow = (props: PropsRow) => {
  const listFilled = fillTo(props.list, MIN_COLUMNS);
  const imgs = [...listFilled, ...listFilled].map((src, i) => {
    return (
      <Picture
        aria-hidden={true}
        content={src}
        className={styles.image2}
        key={i}
      />
    );
  });
  const defaultDuration = 40000;
  const countCoeff = listFilled.length / MIN_COLUMNS;
  const speedNumber = parseFloat('' + props.animationSpeed || '');
  const duration = isNaN(speedNumber) ? 1 : 1 / speedNumber;

  const style = {
    '--count': listFilled.length,
    '--count-coeff': countCoeff,
    '--anim-duration': countCoeff * defaultDuration * duration + 'ms',
  } as React.CSSProperties;
  return (
    <div className={styles.row2} style={style}>
      {imgs}
    </div>
  );
};

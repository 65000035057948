import styles from './style.scss';
import React from 'react';
import { imgUrlCssVar } from '../../../../utils/CssVar';
import { OptionalLinkCardContent } from '../../../../generated/Common';
import { TitleDescription } from '../../../general/titleDescription/TitleDescription';
import { Button } from '../../../inline/button/Button';
import { PoorText, useContentFromCMSRef } from '2mr-cms-frontend';

export const HomeTheFinalStand = (props: OptionalLinkCardContent) => {
  const { ref } = useContentFromCMSRef(props.image);
  return (
    <div
      className={styles.finalStand}
      ref={ref}
      style={imgUrlCssVar('--background-image-url', props.image.value)}
    >
      <div className={styles.container}>
        <TitleDescription title={props.title} description={props.description} />
        {props.link ? (
          <Button
            className={styles.btn}
            style={'filled'}
            color={'accent'}
            href={props.link.url}
          >
            <PoorText content={props.link.title} />
          </Button>
        ) : null}
      </div>
    </div>
  );
};

import { JsonObjectType, oneOf, optional, defineType } from '2mr-cms-typegen';

export const HeaderCommon: JsonObjectType = {
  title: 'richText',
  subTitle: optional('richText'),
  titleAfterSubtitle: 'boolean',
  backgroundUrl: 'image',
  backgroundColor: 'color',
  backgroundGradientDirection: oneOf('left', 'right'),
  backgroundFit: oneOf('contain', 'cover'),
};

defineType(HeaderCommon, 'HeaderCommon', 'Common.ts');
export const HasTitleAndUrl: JsonObjectType = {
  title: 'text',
  url: 'url',
};

export const HasTitleAndUrlAndTarget: JsonObjectType = {
  title: 'text',
  url: 'url',
  targetBlank: 'boolean',
};

defineType(HasTitleAndUrl, 'HasTitleAndUrl', 'Common.ts');
export const LinkImageContent: JsonObjectType = {
  imageUrl: 'image',
  url: optional('url'),
};

defineType(LinkImageContent, 'LinkImageContent', 'Common.ts');
export const SocialNetwork: JsonObjectType = {
  url: 'url',
  logoUrl: 'image',
};
defineType(SocialNetwork, 'SocialNetwork', 'Common.ts');

export const HasTitleAndDescription: JsonObjectType = {
  title: 'text',
  description: 'richText',
};
defineType(HasTitleAndDescription, 'HasTitleAndDescription', 'Common.ts');
export const ImageCardContent: JsonObjectType = {
  ...HasTitleAndDescription,
  image: 'image',
};
defineType(ImageCardContent, 'ImageCardContent', 'Common.ts');

export const LinkCardContent: JsonObjectType = {
  ...HasTitleAndDescription,
  image: 'image',
  link: HasTitleAndUrl,
};
defineType(LinkCardContent, 'LinkCardContent', 'Common.ts');
export const OptionalLinkCardContent: JsonObjectType = {
  ...HasTitleAndDescription,
  image: 'image',
  link: optional(HasTitleAndUrl),
};
defineType(OptionalLinkCardContent, 'OptionalLinkCardContent', 'Common.ts');
export const ThreeImageCardsCardContent: JsonObjectType = {
  ...ImageCardContent,
  tag: optional(['text']),
};

defineType(
  ThreeImageCardsCardContent,
  'ThreeImageCardsCardContent',
  'Common.ts'
);
export const ThreeImageCardsContent: JsonObjectType = {
  title: 'text',
  description: optional('richText'),
  cards: [ThreeImageCardsCardContent],
  link: optional(HasTitleAndUrl),
};

defineType(ThreeImageCardsContent, 'ThreeImageCardsContent', 'Common.ts');

export const ListCardContent: JsonObjectType = {
  title: 'text',
  listIcon: 'image',
  list: ['richText'],
};

defineType(ListCardContent, 'ListCardContent', 'Common.ts');

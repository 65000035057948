import React, { useEffect, useState } from 'react';
import styles from './style.scss';
import { PromoHeaderContent } from '../../../../generated/Promo';
import { clsx, PoorText } from '2mr-cms-frontend';
import { Button } from '../../../inline/button/Button';

export const PromoHeader = ({
  header,
  preHeader,
  backgroundVideo,
  button,
  ytLink,
}: PromoHeaderContent) => {
  const [videoLoaded, setVideoLoaded] = useState(false);

  useEffect(() => {
    setNewTimeout();
  }, []);

  const setNewTimeout = () => {
    setTimeout(() => {
      const video = document.getElementById('bg-video') as any;
      if (video?.readyState === 4) {
        setVideoLoaded(true);
      } else {
        setNewTimeout();
      }
    }, 50);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <h4 className={styles.preHeader}>{preHeader}</h4>
        <h1 className={styles.header}>{header}</h1>
        <Button
          style='filled'
          color='red'
          href={button.url}
          targetBlank={button.targetBlank}
          className={styles.button}
        >
          <PoorText content={button.title} />
        </Button>
        <a
          href={ytLink.url}
          target={ytLink.targetBlank ? '_blank' : undefined}
          className={styles.videoButton}
        >
          <PoorText content={ytLink.title} className={styles.videoButtonText} />
          <img src={ytLink.icon.value} className={styles.videoButtonIcon} />
        </a>
      </div>
      <video
        id='bg-video'
        className={clsx(styles.video, !videoLoaded && styles.videoHidden)}
        autoPlay
        playsInline
        loop
        muted
      >
        <source src={backgroundVideo.video.value} type='video/mp4' />
      </video>
      <img
        className={clsx(styles.video, videoLoaded && styles.videoHidden)}
        src={backgroundVideo.cover.value}
      />
    </div>
  );
};

import React, { useState } from 'react';
import styles from './style.scss';
import { FullWidthBlock } from '../../../general/fullWidthBlock/FullWidthBlock';
import { PromoContactUsContent } from '../../../../generated/Promo';
import { ContactFormBlockContent } from '../../../../generated/ContactContent';
import { ContactUsForm } from '../../contactUs/form/Form';
import { TitleDescription } from '../../../general/titleDescription/TitleDescription';

type Props = PromoContactUsContent & ContactFormBlockContent;

export const PromoContactUs = ({
  id,
  image,
  form,
  submitted: submittedProps,
}: Props) => {
  const [submitted, setSubmitted] = useState(false);

  return (
    <FullWidthBlock bg='black' id={id}>
      <div className={styles.contactUs}>
        <div className={styles.left}>
          <div className={styles.leftTitleDesc}>
            <TitleDescription
              title={form.title}
              description={form.description}
            />
          </div>
          <div className={styles.imageWrapper}>
            <img src={image.value} className={styles.image} />
          </div>
        </div>
        {submitted ? (
          <div className={styles.rightSubmitted}>
            <TitleDescription
              title={submittedProps.title}
              description={submittedProps.description}
            />
          </div>
        ) : (
          <ContactUsForm
            form={form}
            onSubmit={() => setSubmitted(true)}
            className={styles.right}
          />
        )}
      </div>
    </FullWidthBlock>
  );
};

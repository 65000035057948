import React, { FormEventHandler, useState } from 'react';

import styles from './Form.scss';
import {
  ContactFormBlockContent,
  ContactFormContent,
  FormFieldContent,
} from '../../../../generated/ContactContent';
import { FullWidthBlock } from '../../../general/fullWidthBlock/FullWidthBlock';
import { TitleDescription } from '../../../general/titleDescription/TitleDescription';
import { ImageContent, PoorTextContent } from '../../../../generated/StdLib';
import { imgUrlCssVar } from '../../../../utils/CssVar';
import { Button } from '../../../inline/button/Button';
import { clsx, PoorText, useContentFromCMSRef } from '2mr-cms-frontend';

export const ContactFormBlock = (props: ContactFormBlockContent) => {
  const [submitted, setSubmitted] = useState(false);
  const { ref } = useContentFromCMSRef(props.backgroundUrl);
  const style = imgUrlCssVar('--background-image', props.backgroundUrl.value);

  if (submitted) {
    return (
      <FullWidthBlock bg={'dark-gray'} id='contact-us-form'>
        <div className={styles.container} style={style} ref={ref}>
          <div className={styles.left}></div>
          <div className={styles.rightText}>
            <div className={styles.titleDescSubmitted}>
              <TitleDescription
                title={props.submitted.title}
                description={props.submitted.description}
              />
            </div>
            <Button
              style={'air'}
              color={'white'}
              href={props.submitted.button.url}
            >
              <PoorText content={props.submitted.button.title} />
            </Button>
          </div>
        </div>
      </FullWidthBlock>
    );
  }

  return (
    <FullWidthBlock bg={'dark-gray'} id='contact-us-form'>
      <div className={styles.container} style={style}>
        <div className={styles.left}>
          <TitleDescription
            title={props.form.title}
            description={props.form.description}
          />
        </div>
        <ContactUsForm
          form={props.form}
          onSubmit={() => setSubmitted(true)}
          className={styles.right}
        />
      </div>
    </FullWidthBlock>
  );
};

interface Props {
  form: ContactFormContent;
  onSubmit: () => void;
  className?: string;
}

export const ContactUsForm = ({ form, onSubmit, className }: Props) => {
  const [valid, setValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState(new Map<string, string>());

  const setValue = (key: string, value: string) => {
    const n = new Map(values);
    n.set(key, value);
    setValues(n);
  };

  const submit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    setLoading(true);
    fetch('/submitForm', {
      method: 'POST',
      body: JSON.stringify(Object.fromEntries(values.entries())),
    }).then(() => {
      onSubmit();
      setLoading(false);
    });
  };

  const fields = form.fields.map((f) => {
    return <Field {...form} {...f} setValue={setValue} />;
  });

  return (
    <form
      onSubmit={submit}
      className={clsx(className, valid ? styles.validate : '')}
    >
      <div className={styles.requiredLabel}>
        <PoorText content={form.requiredLabel} />
      </div>
      {fields}
      {loading ? (
        <Button
          disabled={true}
          className={clsx(styles.button, styles.buttonSending)}
          style={'air'}
          color={'white'}
          onClick={() => {}}
        >
          <PoorText content={form.sendingButtonText} />
        </Button>
      ) : (
        <Button
          className={styles.button}
          style={'air'}
          color={'white'}
          isSubmit={true}
          onClick={() => {
            setValid(true);
          }}
          value={form.submitButtonText.value}
        />
      )}
    </form>
  );
};

type FieldProps = FormFieldContent & {
  invalidFieldText: PoorTextContent;
  invalidIcon: ImageContent;
  setValue: (key: string, val: string) => void;
};

const Field = (props: FieldProps) => {
  const [validate, setValidate] = useState(false);
  let inputElem;
  const placeholder = props.placeholder || props.title;

  if (props.type === 'multiline text') {
    inputElem = (
      <textarea
        required={props.required}
        className={styles.input}
        placeholder={placeholder.value}
        onInvalid={(e) => {
          e.preventDefault();
        }}
        onChange={(e) => {
          setValidate(true);
          props.setValue(props.title.value, e.currentTarget.value);
        }}
      />
    );
  } else {
    inputElem = (
      <input
        onInvalid={(e) => {
          e.preventDefault();
        }}
        onChange={(e) => {
          setValidate(true);
          props.setValue(props.title.value, e.currentTarget.value);
        }}
        required={props.required}
        className={styles.input}
        type={props.type}
        placeholder={placeholder.value}
      />
    );
  }
  const { ref } = useContentFromCMSRef(props.invalidIcon);
  const style = imgUrlCssVar('--invalid-icon', props.invalidIcon.value);
  return (
    <div
      className={clsx(validate ? styles.validate : '', styles.inputWrapper)}
      style={style}
      ref={ref}
    >
      {inputElem}
      <div aria-hidden={true} className={styles.placeholder}>
        <PoorText content={placeholder} />
      </div>
      <div className={styles.requiredInvalid}>
        <PoorText content={props.invalidFieldText} />
      </div>
    </div>
  );
};

import styles from './style.scss';
import React from 'react';
import { SlicedBlock } from '../../../general/slicedBlock/SlicedBlock';
import { OptionalLinkCardContent } from '../../../../generated/Common';
import { TitleDescription } from '../../../general/titleDescription/TitleDescription';
import { Button } from '../../../inline/button/Button';
import { FullWidthBlock } from '../../../general/fullWidthBlock/FullWidthBlock';
import { Picture, PoorText } from '2mr-cms-frontend';

export const HomeWhatIs = (props: OptionalLinkCardContent) => {
  return (
    <FullWidthBlock bg={'transparent'}>
      <SlicedBlock
        left={
          <div className={styles.imgContainer}>
            <Picture
              aria-hidden={true}
              className={styles.img}
              content={props.image}
            />
          </div>
        }
        right={
          <div className={styles.text}>
            <TitleDescription
              title={props.title}
              description={props.description}
            />
            {props.link ? (
              <Button
                className={styles.btn}
                style={'air'}
                color={'white'}
                href={props.link.url}
              >
                <PoorText content={props.link.title} />
              </Button>
            ) : null}
          </div>
        }
      />
    </FullWidthBlock>
  );
};

import styles from './style.scss';
import React from 'react';
import { imgUrlCssVar } from '../../../utils/CssVar';
import { ImageContent, PoorTextContent } from '../../../generated/StdLib';
import { PoorText, useContentFromCMSRef } from '2mr-cms-frontend';

type Props = {
  tag?: {
    icon?: ImageContent;
    title: PoorTextContent;
  };
};
export const SpecialTag = (props: Props) => {
  const { ref } = useContentFromCMSRef(props.tag?.icon);
  if (!props.tag) {
    return null;
  }
  const style = props.tag.icon
    ? imgUrlCssVar('--tag-icon-url', props.tag.icon.value || '')
    : {};
  return (
    <span ref={ref} className={styles.tag} style={style}>
      <PoorText content={props.tag.title} />
    </span>
  );
};

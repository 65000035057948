import styles from './style.scss';
import React, { useEffect, useState } from 'react';
import { FullWidthBlock } from '../fullWidthBlock/FullWidthBlock';
import { TitleDescription } from '../titleDescription/TitleDescription';
import { CountdownContent } from '../../../generated/Home';
import { formatDate, splitTime } from '../../../utils/dateTime';
import { PoorTextContent } from '../../../generated/StdLib';
import { PoorText } from '2mr-cms-frontend';

type Props = {
  dateTime: number;
  tagText: PoorTextContent;
};
function getDiff(timeMs: number) {
  const now = new Date().getTime();
  const diff = timeMs - now;
  return splitTime(Math.max(0, diff));
}
export const Countdown = (props: Props) => {
  const [diff, setDiff] = useState(getDiff(props.dateTime));
  const tagDate = formatDate(props.dateTime);

  useEffect(() => {
    const id = setInterval(() => {
      setDiff(getDiff(props.dateTime));
    }, 1000);
    return () => {
      clearInterval(id);
    };
  }, [props.dateTime]);

  return (
    <>
      <div className={styles.countdown} role='timer'>
        <div className={styles.countdownItem}>
          <div className={styles.countdownNumber}>{diff.days}</div>
          <div className={styles.countdownLabel}>Days</div>
        </div>
        <div className={styles.countdownItem}>
          <div className={styles.countdownNumber}>{diff.hours}</div>
          <div className={styles.countdownLabel}>Hours</div>
        </div>
        <div className={styles.countdownItem}>
          <div className={styles.countdownNumber}>{diff.minutes}</div>
          <div className={styles.countdownLabel}>Minutes</div>
        </div>
        <div className={styles.countdownItem}>
          <div className={styles.countdownNumber}>{diff.seconds}</div>
          <div className={styles.countdownLabel}>Seconds</div>
        </div>
      </div>
      <time className={styles.tag}>
        <PoorText content={props.tagText} />
        {tagDate}
      </time>
    </>
  );
};
export const HomeCountdown = (props: CountdownContent) => {
  return (
    <FullWidthBlock bg={'gray-light'}>
      <div className={styles.container}>
        <div className={styles.subtitle}>
          <PoorText content={props.subtitle} />
        </div>
        <TitleDescription title={props.title} />
        <Countdown dateTime={props.eventDateTime} tagText={props.tagText} />
      </div>
    </FullWidthBlock>
  );
};

import React from 'react';
import styles from './style.scss';
import { clsx, RichText } from '2mr-cms-frontend';
import { ImageContent, RichTextContent } from '../../../../generated/StdLib';

interface Props {
  title: string;
  description: RichTextContent;
  bgImage: ImageContent;
  className?: string;
}

export const PromoGridsHeader = ({
  title,
  description,
  bgImage,
  className,
}: Props) => (
  <div className={styles.gridHeader}>
    <div className={styles.bgImageWrapper}>
      <img src={bgImage.value} className={styles.bgImage} />
    </div>
    <div className={clsx(styles.content, className)}>
      <h2 className={styles.title}>{title}</h2>
      <RichText content={description} className={styles.description} />
    </div>
  </div>
);

import styles from './style.scss';
import React from 'react';
import { OptionalLinkCardContent } from '../../../../generated/Common';
import { TitleDescription } from '../../../general/titleDescription/TitleDescription';
import { OptionalButton } from '../../../inline/button/OptionalButton';
import { Picture } from '2mr-cms-frontend';

type Props = OptionalLinkCardContent;
export const GroupPackage = (props: Props) => {
  return (
    <div className={styles.background}>
      <div className={styles.container}>
        <Picture
          aria-hidden={true}
          className={styles.img}
          content={props.image}
        />
        <div className={styles.text}>
          <TitleDescription
            className={styles.titleDesc}
            title={props.title}
            description={props.description}
          />
          <OptionalButton
            className={styles.btn}
            style={'air'}
            color={'white'}
            link={props.link}
          />
        </div>
      </div>
    </div>
  );
};

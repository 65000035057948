import React from 'react';
import styles from './style.scss';
import { FullWidthBlock } from '../../../general/fullWidthBlock/FullWidthBlock';
import { EventSpectatorGrid } from '../../event/eventSpectatorBlock/EventSpectatorBlock';
import { PromoAfterPartyContent } from '../../../../generated/Promo';
import { ForSpectatorContent } from '../../../../generated/EventContent';
import { PromoGridsHeader } from '../gridsHeader/PromoGridsHeader';
import { useWindowSize } from '../../../../utils/useWindowSize';

export const PromoAfterParty = ({
  grid,
  id,
  headerTitle,
  headerDescription,
  bgImage,
}: PromoAfterPartyContent & ForSpectatorContent) => {
  const [sizeX] = useWindowSize();
  const isTablet = sizeX < 1100;

  return (
    <FullWidthBlock bg='transparent' id={id}>
      <PromoGridsHeader
        title={headerTitle}
        description={headerDescription}
        bgImage={bgImage}
        className={styles.headerPadding}
      />
      <EventSpectatorGrid cards={grid} offset={isTablet ? -50 : -139} />
    </FullWidthBlock>
  );
};

import { typeDefiner } from '2mr-cms-typegen';
import {
  HasTitleAndDescription,
  HeaderCommon,
  OptionalLinkCardContent,
} from './Common';
import { JsonObjectType, optional } from '2mr-cms-typegen';

const defineType = typeDefiner('PricingContent.ts');

export const SpecialTag: JsonObjectType = {
  icon: 'image',
  title: 'text',
};
export const PackageContent: JsonObjectType = {
  tag: optional(SpecialTag),
  image: 'image',
  crossedPrice: optional('text'),
  price: {
    bigText: optional('text'),
    smallText: optional('text'),
  },
  title: 'text',
  description: 'richText',
  buttonTitle: 'text',
  url: 'url',
};
defineType(PackageContent, 'PackageContent');
export const PackagesBlockContent: JsonObjectType = {
  ...HasTitleAndDescription,
  packages: [PackageContent],
};
defineType(PackagesBlockContent, 'PackagesBlockContent');

export const SurvivorKitItemContent: JsonObjectType = {
  title: 'text',
  description: 'richText',
  image: 'image',
  tag: optional(SpecialTag),
  url: optional('url'),
};
defineType(SurvivorKitItemContent, 'SurvivorKitItemContent');

export const SurvivorKitContent: JsonObjectType = {
  ...HasTitleAndDescription,
  items: [SurvivorKitItemContent],
};
defineType(SurvivorKitContent, 'SurvivorKitContent');

export const PricingContent: JsonObjectType = {
  header: HeaderCommon,
  packages: PackagesBlockContent,
  groupTicket: OptionalLinkCardContent,
  survivorKit: SurvivorKitContent,
};
defineType(PricingContent, 'PricingContent');
export const importPricingContent = () => {};

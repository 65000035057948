import styles from './style.scss';
import React, { ReactNode } from 'react';
import { ThreeColumnCards } from '../threeColumnCards/ThreeColumnCards';
import {
  HasTitleAndUrl,
  ImageCardContent,
  ThreeImageCardsContent,
} from '../../../generated/Common';
import { Button } from '../../inline/button/Button';
import { FullWidthBlock } from '../fullWidthBlock/FullWidthBlock';
import { PoorTextContent, RichTextContent } from '../../../generated/StdLib';
import { PoorText, RichText } from '2mr-cms-frontend';
import { ImageCard, ImageCard2, ImageCard4 } from '../ImageCard/ImageCard';

export type ThreeCardsProps = {
  title: PoorTextContent;
  description?: RichTextContent;
  cards: ReactNode[];
  link?: HasTitleAndUrl;
  className?: string;
};
export const ThreeCards = (props: ThreeCardsProps) => {
  return (
    <div className={`${styles.container} ${props.className}`}>
      <h2 className={styles.title}>
        <PoorText content={props.title} />
      </h2>
      <RichText
        element={'div'}
        className={styles.description}
        content={props.description}
      />
      <ThreeColumnCards cards={props.cards} />
      {props.link ? (
        <Button
          className={styles.btn}
          style={'air'}
          color={'black'}
          href={props.link.url}
        >
          <PoorText content={props.link.title} />
        </Button>
      ) : null}
    </div>
  );
};
export type HomePageEntryPassesProps = {
  title: PoorTextContent;
  description?: RichTextContent;
  cards: ImageCardContent[];
  link?: HasTitleAndUrl;
};

export const HomePageEntryPasses = (props: HomePageEntryPassesProps) => {
  const { cards, ...rest } = props;
  const cardElems = cards.map((c, i) => {
    return <ImageCard key={i} {...c} />;
  });
  return (
    <FullWidthBlock bg={'gray'}>
      <ThreeCards cards={cardElems} {...rest} />
    </FullWidthBlock>
  );
};
export type HomePageUltimateExpirienceProps = HomePageEntryPassesProps;

export const HomePageUltimateExpirience = (
  props: HomePageUltimateExpirienceProps
) => {
  const { cards, ...rest } = props;
  const cardElems = cards.map((c, i) => {
    return <ImageCard2 key={i} {...c} />;
  });
  return (
    <FullWidthBlock bg={'white'}>
      <ThreeCards
        cards={cardElems}
        className={styles.ultimateExpirience}
        {...rest}
      />
    </FullWidthBlock>
  );
};

export const AboutPageWarPlan = (props: ThreeImageCardsContent) => {
  const { cards, ...rest } = props;
  const cardElems = cards.map((c, i) => {
    return <ImageCard4 key={i} {...c} />;
  });
  return (
    <FullWidthBlock bg={'accent'}>
      <ThreeCards cards={cardElems} className={styles.warPlan} {...rest} />
    </FullWidthBlock>
  );
};

import React, { useState } from 'react';
import styles from './style.scss';
import { SwiperSlide } from 'swiper/react';
import { SliderNavigation } from '../slider/navigation/SliderNavigation';
import { SliderState, SliderFade } from '../slider/SliderFade';
import { SliderPaginationLine } from '../slider/pagination/SliderPaginationLine';
import { HeaderSlide } from '../headerSlide/HeaderSlide';
import { FullWidthBlock } from '../fullWidthBlock/FullWidthBlock';
import { HeaderSliderSlideContent } from '../../../generated/Home';
import { Swiper as ISwiper } from 'swiper';

interface Props {
  speed?: number;
  slides: HeaderSliderSlideContent[];
}

export const DEFAULT_SLIDER_SPEED = 2000;
export const DEFAULT_SLIDER_DURATION = 7000;
export const HeaderSlider = ({
  slides,
  speed = DEFAULT_SLIDER_DURATION,
}: Props) => {
  const DEFAULT_SLIDER_STATE: SliderState = {
    ref: undefined,
    pagination: { current: 1, total: slides.length },
    navigation: { left: true, right: false },
  };
  const [state, setState] = useState<SliderState>(DEFAULT_SLIDER_STATE);
  const [disableAnimation, setDisableAnimation] = useState(false);
  const { handlePaginationClick, handleLeftClick, handleRightClick } =
    useSliderControls(state.ref, state.pagination.total);

  return (
    <div className={styles.headerSlider}>
      <SliderFade
        setState={setState}
        className={styles.slider}
        speed={DEFAULT_SLIDER_SPEED}
        duration={speed}
      >
        {slides.map((slide, index) => (
          <SwiperSlide key={`slide-${index}`} className={styles.slide}>
            <HeaderSlide {...slide} />
          </SwiperSlide>
        ))}
      </SliderFade>
      {state.pagination.total > 1 && (
        <>
          <FullWidthBlock>
            <div className={styles.container}>
              <SliderPaginationLine
                speed={speed}
                disableAnimation={disableAnimation}
                current={state.pagination.current}
                total={state.pagination.total}
                onClick={(index) => {
                  setDisableAnimation(true);
                  handlePaginationClick(index);
                }}
                className={styles.pagination}
              />
            </div>
          </FullWidthBlock>
          <SliderNavigation
            color='black'
            leftDisabled={false}
            rightDisabled={false}
            onLeftClick={() => {
              setDisableAnimation(true);
              handleLeftClick();
            }}
            onRightClick={() => {
              setDisableAnimation(true);
              handleRightClick();
            }}
          />
        </>
      )}
    </div>
  );
};

const useSliderControls = (slider: ISwiper | undefined, total: number) => {
  const handlePaginationClick = (newIndex: number) => {
    if (slider) {
      slider.slideTo(newIndex);
    }
  };

  const handleLeftClick = () => {
    if (slider) {
      slider.slideTo((slider.realIndex - 1 + total) % total);
    }
  };

  const handleRightClick = () => {
    if (slider) {
      slider.slideTo((slider.realIndex + 1) % total);
    }
  };

  return { handlePaginationClick, handleLeftClick, handleRightClick };
};

import styles from './style.scss';
import React from 'react';
import { HomeJoinCommunityContent } from '../../../../generated/Home';
import { TitleDescription } from '../../../general/titleDescription/TitleDescription';
import { Button } from '../../../inline/button/Button';
import { imgUrlCssVar } from '../../../../utils/CssVar';
import { Picture, PoorText, useContentFromCMSRef } from '2mr-cms-frontend';

export const HomeJoinCommunity = (props: HomeJoinCommunityContent) => {
  const { ref } = useContentFromCMSRef(props.backgroundImageRight);
  const bgStyle = {
    ...imgUrlCssVar('--background-image-left', props.backgroundImageLeft.value),
    ...imgUrlCssVar(
      '--background-image-right',
      props.backgroundImageRight.value
    ),
  };
  return (
    <div className={styles.block} style={bgStyle} ref={ref}>
      <div className={styles.container}>
        <TitleDescription
          className={styles.text}
          title={props.title}
          description={props.description}
        />
        <Button
          className={styles.btn}
          style={'air'}
          color={'white'}
          href={props.button.url}
        >
          {props.button.icon && (
            <Picture
              aria-hidden={true}
              className={styles.btnIcon}
              content={props.button.icon}
            />
          )}
          <PoorText content={props.button.title} />
        </Button>
      </div>
    </div>
  );
};

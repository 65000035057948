import styles from './style.scss';
import React from 'react';

type Props = React.PropsWithChildren & {
  className?: string;
  style?: React.CSSProperties;
  bg?:
    | 'transparent'
    | 'white'
    | 'gray'
    | 'gray-light'
    | 'black'
    | 'accent'
    | 'red-black-vertical'
    | 'red-black-horizontal'
    | 'dark-gray';
  id?: string;
};
export const FullWidthBlock = (props: Props) => {
  return (
    <div
      style={props.style}
      id={props.id}
      className={`${props.className || ''} ${styles.fullWidthBlock} ${
        styles[props.bg || 'transparent']
      }`}
    >
      {props.children}
    </div>
  );
};

import React from 'react';
import { AboutContent } from '../generated/About';
import { AboutPageWarPlan } from '../component/general/threeCards/ThreeCards';
import { AboutOurEvolution } from '../component/pages/about/aboutOurEvolution/AboutOurEvolution';
import { HistoryGrid } from '../component/pages/about/historyGrid/HistoryGrid';
import { InteractiveMap } from '../component/general/interactiveMap/InteractiveMap';
import { MapDataTableMobile } from '../component/general/interactiveMap/MapDataTableMobile';

type Props = {
  content: AboutContent;
};

export const AboutPage = ({ content }: Props) => {
  return (
    <>
      <InteractiveMap {...content.interactiveMap} />
      <MapDataTableMobile {...content.interactiveMap} />
      <HistoryGrid {...content.historyGrid} />
      <AboutPageWarPlan {...content.warPlan} />
      <AboutOurEvolution {...content.ourEvolution} />
    </>
  );
};

import React from 'react';
import { Pagination, Swiper as ISwiper } from 'swiper';
import { Swiper } from 'swiper/react';

export interface SliderState {
  pagination: {
    current: number;
    total: number;
  };
  navigation: {
    left: boolean;
    right: boolean;
  };
  ref: ISwiper | undefined;
}

interface Props {
  children: React.ReactNode;
  setState: (setter: (prev: SliderState) => SliderState) => void;
  className?: string;
  spaceBetween?: number;
  slidesPerView?: number | 'auto';
}

export const Slider = ({
  children,
  setState,
  className,
  slidesPerView,
  spaceBetween,
}: Props) => {
  const initialize = (swiper: ISwiper) => {
    setState((prev) => ({
      ...prev,
      ref: swiper,
      navigation: { left: swiper.isBeginning, right: swiper.isEnd },
    }));
  };

  const handlePaginationChange = (
    swiper: ISwiper,
    current: number,
    total: number
  ) => {
    setState(() => ({
      ref: swiper,
      navigation: { left: swiper.isBeginning, right: swiper.isEnd },
      pagination: { current, total },
    }));
  };

  return (
    <Swiper
      modules={[Pagination]}
      spaceBetween={spaceBetween}
      slidesPerView={slidesPerView}
      direction='horizontal'
      loop={false}
      slideToClickedSlide={false}
      className={className}
      pagination={{
        type: 'custom',
        renderCustom: (swiper, current, total) => {
          handlePaginationChange(swiper, current, total);
        },
      }}
      onSwiper={(swiper) => initialize(swiper)}
    >
      {children}
    </Swiper>
  );
};

export const useSliderControls = (slider: ISwiper | undefined) => {
  const handlePaginationClick = (newIndex: number) => {
    if (slider) {
      slider.slideTo(newIndex);
    }
  };

  const handleLeftClick = () => {
    if (slider) {
      slider.slidePrev();
    }
  };

  const handleRightClick = () => {
    if (slider) {
      slider.slideNext();
    }
  };

  return { handlePaginationClick, handleLeftClick, handleRightClick };
};

export const DEFAULT_SLIDER_STATE: SliderState = {
  ref: undefined,
  pagination: { current: 0, total: 3 },
  navigation: { left: true, right: false },
};

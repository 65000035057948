import styles from './style.scss';
import React, { ReactNode } from 'react';

type Props = {
  top: ReactNode;
  bottom: ReactNode;
  url?: string;
  classname?: string;
};
export const FlipCard = (props: Props) => {
  const content = (
    <>
      <div className={styles.top}>{props.top}</div>
      <div className={styles.bottom}>{props.bottom}</div>
    </>
  );
  if (props.url) {
    return (
      <a className={styles.card} href={props.url}>
        {content}
      </a>
    );
  }
  return (
    <div className={styles.card + ' ' + (props.classname || '')}>{content}</div>
  );
};

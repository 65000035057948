import React, { ReactNode } from 'react';
import styles from './style.scss';
import { Optional, PoorTextContent } from '../../../generated/StdLib';
import { NewsArticlePreviewContent } from '../../../generated/NewsContent';
import { NEWS_SINGLE_BASE_URL } from '../../../data/Urls';
import { FullWidthBlock } from '../fullWidthBlock/FullWidthBlock';
import { Link } from 'react-router-dom';
import { clsx, PoorText } from '2mr-cms-frontend';

const themes = {
  'white-card': {
    bg: 'transparent',
    cardClass: styles.cardWhite,
  },
  'gray-card': {
    bg: 'white',
    cardClass: styles.cardGray,
  },
} as const;
export type RecentNewsBlockTheme = keyof typeof themes;
type Props = {
  title: Optional<PoorTextContent>;
  newsList: NewsArticlePreviewContent[];
  buttonLink: ReactNode;
  theme?: RecentNewsBlockTheme;
};
export const RecentNewsBlock = (props: Props) => {
  const style = themes[props.theme || 'gray-card'];

  const elems = props.newsList.map((card, i) => {
    const cardElem = (
      <Link
        key={i}
        className={styles.card}
        to={NEWS_SINGLE_BASE_URL + card.slug}
      >
        <time className={styles.cardDate}>
          <PoorText content={card.date} />
        </time>
        <span className={styles.cardTitle}>
          <PoorText content={card.title} />
        </span>
      </Link>
    );
    return <li key={card.slug}>{cardElem}</li>;
  });
  return (
    <FullWidthBlock bg={style.bg}>
      <div className={clsx(styles.news, style.cardClass)}>
        {props.title ? (
          <h2 className={styles.title}>
            <PoorText content={props.title} />
          </h2>
        ) : null}
        <ul className={styles.list}>{elems}</ul>
        {props.buttonLink}
      </div>
    </FullWidthBlock>
  );
};

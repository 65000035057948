import styles from './SocialNetworks.scss';
import { imgUrlCssVar } from '../../../utils/CssVar';
import React from 'react';
import { SocialNetwork } from '../../../generated/Common';
import { clsx, useContentFromCMSRef } from '2mr-cms-frontend';

export type SocialNetworksProps = {
  socials: SocialNetwork[];
  color: 'dark' | 'light';
};

const Social = ({ url, logoUrl }: SocialNetwork) => {
  const { ref } = useContentFromCMSRef(logoUrl);
  return (
    <>
      <a
        href={url}
        ref={ref}
        className={styles.socialNetwork}
        style={imgUrlCssVar('--social-network-logo-url', logoUrl.value)}
      />
    </>
  );
};

export const SocialNetworks = ({ socials, color }: SocialNetworksProps) => {
  return (
    <div
      className={clsx(
        styles.socialNetworks,
        color === 'light' && styles.light,
        color === 'dark' && styles.dark
      )}
    >
      {socials.map((s, i) => (
        <Social key={i} {...s} />
      ))}
    </div>
  );
};

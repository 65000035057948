import React, { useEffect, useState } from 'react';

import { Footer } from './component/general/footer/Footer';
import { NavPanel } from './component/general/navigation/navPanel/NavPanel';
import { NavMobile } from './component/general/navigation/navMobile/NavMobile';
import { Loader } from './component/general/loader/Loader';
import { Router } from './Router';
import { BrowserRouter, useLocation } from 'react-router-dom';
import { HomeContent } from './generated/Home';
import { FooterContent, NavigationContent } from './generated/Navigation';
import { AboutContent } from './generated/About';
import { CharactersContent } from './generated/Characters';
import { EventContent } from './generated/EventContent';
import { PricingContent } from './generated/PricingContent';
import { ContactContent } from './generated/ContactContent';
import { NewsHeaderContent } from './generated/NewsHeaderContent';
import { contentsAndIds } from '../../common/src/types/ContentsAndIds';
import { ContentFromCMSTooltip, prepareContent } from '2mr-cms-frontend';
import { initAnal } from './utils/initiateAnalytics';
import { PromoContent } from './generated/Promo';
import { PromoNavigationContent } from './generated/PromoNavigation';

type Content = {
  navigationContent: NavigationContent;
  promoNavigationContent: PromoNavigationContent;
  footerContent: FooterContent;
  homeContent: HomeContent;
  promoContent: PromoContent;
  aboutContent: AboutContent;
  eventContent: EventContent;
  charactersContent: CharactersContent;
  newsHeaderContent: NewsHeaderContent;
  pricingContent: PricingContent;
  contactContent: ContactContent;
};

const loadContent = async () => {
  return Object.fromEntries(
    await Promise.all(
      contentsAndIds.map(({ id, definition }) =>
        fetch(`/content?id=${id}&enrich=1`)
          .then((v) => v.json())
          .then((json) => [
            id + 'Content',
            prepareContent(json, id, '', definition),
          ])
      )
    )
  );
};

const promoMode = true;

export const App = () => {
  const [content, setContent] = useState<Content | undefined>(undefined);

  useEffect(() => {
    loadContent().then((content) => {
      setContent(content as Content);
    });
    initAnal('GTM-PZ2M3CK');
  }, []);

  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

  const closeMobileNav = () => {
    setIsMobileNavOpen(false);
  };

  const openMobileNav = () => {
    setIsMobileNavOpen(true);
  };

  if (!content) {
    return <Loader />;
  }

  return (
    <BrowserRouter>
      <ContentFromCMSTooltip />
      <ScrollToTop />
      <NavPanel
        {...{
          ...{ onOpenMenu: openMobileNav },
          ...content.navigationContent,
          ...content.promoNavigationContent,
        }}
        promo={promoMode}
      />
      <NavMobile
        {...{
          ...{
            opened: isMobileNavOpen,
            onClose: closeMobileNav,
          },
          ...content.footerContent,
          ...content.navigationContent,
          ...content.promoNavigationContent,
        }}
        promo={promoMode}
      />
      <Router {...content} promo={promoMode} />
      <Footer
        {...{
          ...content.footerContent,
          ...content.navigationContent,
          ...content.promoNavigationContent,
        }}
        promo={promoMode}
      />
    </BrowserRouter>
  );
};

export function ScrollToTop() {
  const { pathname, hash } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    const anchor = hash.split('#')[1];
    const el = document.getElementById(anchor);
    if (el) {
      const rect = el.getBoundingClientRect();
      window.scrollBy({ top: rect.top - 72 - 92, behavior: 'smooth' });
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [hash]);

  return null;
}

import React, { useEffect, useState } from 'react';
import { Article } from '../component/pages/newsSingle/article/Article';
import { NEWS_URL } from '../data/Urls';
import { RecentNewsBlockSmart } from '../component/general/recentNewsBlockSmart/RecentNewsBlockSmart';
import { fetchNewsSingle } from '../utils/fetch';
import { NewsArticleContent } from '../generated/NewsContent';
import { useNavigate, useParams } from 'react-router-dom';
import { Loader } from '../component/general/loader/Loader';
import { PoorTextContent } from '../generated/StdLib';
import { NewsArticleContentDefinition } from '../types/News';
import { prepareContent } from '2mr-cms-frontend';

interface Props {
  content: {
    readNextHeader?: PoorTextContent;
  };
}

export const NewsSinglePage = ({ content }: Props) => {
  const [articleContent, setArticleContent] = useState<NewsArticleContent>();
  const { slug } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!slug) {
      navigate(NEWS_URL);
      return;
    }
    fetchNewsSingle(slug)
      .then((responseArray: NewsArticleContent[]) => {
        if (responseArray.length < 1) {
          throw new Error(`Content not found: ${slug}`);
        }
        return prepareContent(
          responseArray[0],
          'news',
          '.' + (responseArray[0] as any).__index,
          NewsArticleContentDefinition
        );
      })
      .then((newsSingle) => {
        setArticleContent(newsSingle);
      })
      .catch(() => navigate(NEWS_URL));
  }, [slug]);

  if (!articleContent) {
    return <Loader />;
  }
  return (
    <>
      <Article
        {...articleContent}
        backButton={{ label: 'Back', url: NEWS_URL }}
      />

      <RecentNewsBlockSmart
        theme={'white-card'}
        showPerPage={4}
        title={content.readNextHeader}
        buttonLink={{ label: undefined, url: NEWS_URL }}
        hideBtn={true}
      />
    </>
  );
};

import { HasTitleAndUrl } from './Common';
import { JsonObjectType } from '2mr-cms-typegen';
import { typeDefiner } from '2mr-cms-typegen';

const defineType = typeDefiner('PromoNavigation.ts');

export const PromoNavigationContent: JsonObjectType = {
  promoSections: [HasTitleAndUrl],
};
defineType(PromoNavigationContent, 'PromoNavigationContent');

export const importPromoNavigationContent = () => {};

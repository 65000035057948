import styles from './style.scss';
import React from 'react';
import { FullWidthBlock } from '../../../general/fullWidthBlock/FullWidthBlock';
import {
  SurvivorKitContent,
  SurvivorKitItemContent,
} from '../../../../generated/PricingContent';
import { SpecialTag } from '../../../inline/specialTag/SpecialTag';
import { FlipCard } from '../../../general/flipCard/FlipCard';
import { clsx, Picture, PoorText, RichText } from '2mr-cms-frontend';

export const SurvivorKit = (props: SurvivorKitContent) => {
  const elems = props.items.map((item, i) => {
    return <SurvivorKitItem {...item} key={i} />;
  });
  return (
    <FullWidthBlock className={styles.background}>
      <div className={styles.container}>
        <h2 className={styles.title}>
          <PoorText content={props.title} />
        </h2>
        <RichText
          element={'div'}
          className={styles.description}
          content={props.description}
        />
        <div className={styles.grid}>{elems}</div>
      </div>
    </FullWidthBlock>
  );
};

export const SurvivorKitItem = (props: SurvivorKitItemContent) => {
  const specialClass = props.tag ? styles.special : '';

  return (
    <div className={styles.card}>
      <FlipCard
        url={props.url}
        top={
          <>
            <div className={styles.top}>
              <SpecialTag tag={props.tag} />
              <div className={styles.imgContainer}>
                <Picture
                  aria-hidden={true}
                  className={styles.image}
                  content={props.image}
                />
              </div>
              <div className={styles.cardTitleTop} aria-hidden={true}>
                <PoorText content={props.title} />
              </div>
            </div>
          </>
        }
        bottom={
          <div className={clsx(styles.bottom, specialClass)}>
            <div className={styles.cardTitleBottom}>
              <PoorText content={props.title} />
            </div>
            <RichText
              element={'div'}
              className={styles.cardDescBottom}
              content={props.description}
            ></RichText>
          </div>
        }
      />
    </div>
  );
};

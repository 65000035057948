import React from 'react';

import styles from './style.scss';
import { ForSpectatorContent } from '../../../../generated/EventContent';
import { EventRole } from '../eventRole/EventRole';
import { FlipCard } from '../../../general/flipCard/FlipCard';
import { FullWidthBlock } from '../../../general/fullWidthBlock/FullWidthBlock';
import { clsx, Picture, PoorText, RichText } from '2mr-cms-frontend';
import { ImageCardContent } from '../../../../generated/Common';

export const EventSpectatorBlock = (props: ForSpectatorContent) => {
  return (
    <>
      <EventRole {...props} />
      <FullWidthBlock bg={'transparent'}>
        <EventSpectatorGrid cards={props.grid} />
      </FullWidthBlock>
    </>
  );
};

interface Props {
  cards: ImageCardContent[];
  className?: string;
  offset?: number;
}

export const EventSpectatorGrid = ({
  cards,
  className,
  offset = -60,
}: Props) => (
  <div
    className={clsx(styles.grid, className)}
    style={{ transform: `translateY(${offset}px)` }}
  >
    {cards.map((item, i) => (
      <FlipCard
        key={i}
        top={
          <div className={styles.top} aria-hidden={true}>
            <div className={styles.imgContainer}>
              <Picture
                aria-hidden={true}
                className={styles.image}
                content={item.image}
              />
            </div>
            <div className={styles.cardTitleTop}>
              <PoorText content={item.title} />
            </div>
          </div>
        }
        bottom={
          <div className={styles.bottom}>
            <div className={styles.cardTitleBottom}>
              <PoorText content={item.title} />
            </div>
            <RichText
              element={'div'}
              className={styles.cardDescBottom}
              content={item.description}
            ></RichText>
          </div>
        }
      />
    ))}
  </div>
);

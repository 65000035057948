import React from 'react';
import styles from './SliderNavigation.scss';
import { clsx } from '2mr-cms-frontend';

type NavigationProps = {
  color: 'black' | 'blue';
  leftDisabled: boolean;
  rightDisabled: boolean;
  onLeftClick: () => void;
  onRightClick: () => void;
  className?: string;
};

export const SliderNavigation = ({
  color,
  leftDisabled,
  rightDisabled,
  onLeftClick,
  onRightClick,
  className,
}: NavigationProps) => {
  return (
    <div className={clsx(styles.sliderNavigation, className)}>
      <div
        className={clsx(
          styles.arrow,
          color === 'blue' && styles.arrowBlue,
          leftDisabled && styles.arrowDisabled
        )}
        onClick={leftDisabled ? undefined : onLeftClick}
      >
        <SliderNavigationArrow />
      </div>
      <div
        className={clsx(
          styles.arrow,
          color === 'blue' && styles.arrowBlue,
          rightDisabled && styles.arrowDisabled,
          styles.arrowRotated
        )}
        onClick={rightDisabled ? undefined : onRightClick}
      >
        <SliderNavigationArrow />
      </div>
    </div>
  );
};

const SliderNavigationArrow = () => (
  <svg
    width='11'
    height='16'
    viewBox='0 0 11 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M0.935338 8.77145C0.450385 8.37147 0.450384 7.62853 0.935338 7.22855L8.86372 0.689329C9.51596 0.151371 10.5 0.615316 10.5 1.46078L10.5 14.5392C10.5 15.3847 9.51596 15.8486 8.86372 15.3107L0.935338 8.77145Z'
      fill='white'
    />
  </svg>
);

import React from 'react';
import styles from './style.scss';
import { PromoObstaclesContent } from '../../../../generated/Promo';
import { EventSurvivorGrid } from '../../event/eventSurvivorBlock/EventSurvivorBlock';
import { FullWidthBlock } from '../../../general/fullWidthBlock/FullWidthBlock';
import { ForSurvivorContent } from '../../../../generated/EventContent';

import { PromoGridsHeader } from '../gridsHeader/PromoGridsHeader';
import { useWindowSize } from '../../../../utils/useWindowSize';

export const PromoObstacles = ({
  id,
  bgImage,
  headerDescription,
  headerTitle,
  levels,
  levelsIcons,
  grid,
}: PromoObstaclesContent & ForSurvivorContent) => {
  const [sizeX] = useWindowSize();
  const isTablet = sizeX < 1100;
  const isMobile = sizeX < 650;

  return (
    <FullWidthBlock id={id}>
      <PromoGridsHeader
        title={headerTitle}
        description={headerDescription}
        bgImage={bgImage}
        className={styles.headerPadding}
      />
      <EventSurvivorGrid
        cards={grid}
        levels={levels}
        icon={levelsIcons}
        offset={isMobile ? -50 : isTablet ? -32 : -84}
      />
    </FullWidthBlock>
  );
};

import React from 'react';
import { HomeContent } from '../generated/Home';
import {
  HomePageEntryPasses,
  HomePageUltimateExpirience,
} from '../component/general/threeCards/ThreeCards';
import { Comrades } from '../component/pages/home/comrades/Comrades';
import { HomeTheFinalStand } from '../component/pages/home/homeTheFinalStand/HomeTheFinalStand';
import { FullWidthBlock } from '../component/general/fullWidthBlock/FullWidthBlock';
import { HomeJoinCommunity } from '../component/pages/home/homeJoinCommunity/HomeJoinCommunity';
import { PhotosDecorative } from '../component/general/photosDecorative/PhotosDecorative';
import { HomeHowToGet } from '../component/pages/home/HomeHowToGet/HomeHowToGet';
import { HomeCountdown } from '../component/general/countdown/Countdown';
import { HomeWhatIs } from '../component/pages/home/homeWhatIs/HomeWhatIs';
import { InfectedWorldwide } from '../component/general/infectedWorldwide/InfectedWorldwide';
import { HeaderSlider } from '../component/general/headerSlider/HeaderSlider';
import { RecentNewsBlockSmart } from '../component/general/recentNewsBlockSmart/RecentNewsBlockSmart';
import { HomeVideo } from '../component/pages/home/homeVideo/HomeVideo';

type HomePageProps = {
  homeContent: HomeContent;
};

export const HomePage = ({ homeContent }: HomePageProps) => {
  return (
    <>
      <HeaderSlider {...homeContent.headerSlider} />
      <HomeVideo {...homeContent.videoContent} />
      <HomeCountdown {...homeContent.countdown} />
      <RecentNewsBlockSmart {...homeContent.recentNewsBlock} showPerPage={5} />
      <HomeWhatIs {...homeContent.whatIs} />
      <HomePageUltimateExpirience {...homeContent.ultimateExpirience} />

      <InfectedWorldwide {...homeContent.infectedWorldwide} />
      <PhotosDecorative {...homeContent.decorativePhotos} />
      <HomeHowToGet {...homeContent.howToGetTickets} />
      <HomePageEntryPasses {...homeContent.entryPasses} />
      <HomeJoinCommunity {...homeContent.joinCommunity} />
      <FullWidthBlock bg={'red-black-vertical'}>
        <Comrades {...homeContent.comrades} />
        <Comrades {...homeContent.outbreakNews} />
        <HomeTheFinalStand {...homeContent.finalStand} />
      </FullWidthBlock>
    </>
  );
};

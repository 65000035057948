import styles from './NavLink.scss';
import React from 'react';
import { Link } from 'react-router-dom';
import { PoorTextContent } from '../../../../generated/StdLib';
import { clsx, PoorText } from '2mr-cms-frontend';

export type LinkProps = {
  href: string;
  title: PoorTextContent;
  opaq?: boolean;
};

export const NavLink = ({ href, title, opaq }: LinkProps) => {
  if (href.indexOf('mailto:') === 0) {
    return (
      <a href={href} className={clsx(styles.link, opaq && styles.opaq)}>
        <PoorText content={title} />
      </a>
    );
  }
  return (
    <Link to={href} className={clsx(styles.link, opaq && styles.opaq)}>
      <PoorText content={title} />
    </Link>
  );
};

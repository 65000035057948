import { HasTitleAndUrl, SocialNetwork } from './Common';
import { JsonObjectType, optional } from '2mr-cms-typegen';
import { typeDefiner } from '2mr-cms-typegen';

const defineType = typeDefiner('Navigation.ts');

export const HeaderTicketContent: JsonObjectType = {
  title: optional('string'),
  getTicketButton: HasTitleAndUrl,
  smallLogoUrl: optional('image'),
};

export const NavigationContent: JsonObjectType = {
  ticketBar: optional(HeaderTicketContent),
  fullLogoUrl: 'image',
  smallLogoUrl: 'image',
  closeIconUrl: 'image',
  menuIconUrl: 'image',
  sections: [HasTitleAndUrl],
};
defineType(NavigationContent, 'NavigationContent');

export const FooterContent: JsonObjectType = {
  companyName: 'text',
  footerFullLogoUrl: 'image',
  socials: [SocialNetwork],
};
defineType(FooterContent, 'FooterContent');

export const importNavigationContent = () => {};

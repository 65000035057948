import React from 'react';

import { ColoredBlockConent } from '../../../../generated/Characters';
import { imgUrlToCss, objToCssStyle } from '../../../../utils/CssVar';
import { FullWidthBlock } from '../../../general/fullWidthBlock/FullWidthBlock';
import styles from './style.scss';
import { OptionalButton } from '../../../inline/button/OptionalButton';
import {
  Picture,
  PoorText,
  RichText,
  useContentFromCMSRef,
} from '2mr-cms-frontend';

export const CharacterBlock = (props: ColoredBlockConent) => {
  const { ref } = useContentFromCMSRef(props.backgroundImage);
  const style = objToCssStyle({
    colorBg: props.backgroundColor,
    backgroundImage: imgUrlToCss(props.backgroundImage.value),
  });
  return (
    <div className={styles.coloredBlock} style={style} ref={ref}>
      <div className={styles.bgContainer}>
        <div className={styles.container}>
          <div className={styles.textWrapper}>
            {props.titleImage ? (
              <Picture
                aria-hidden={true}
                className={styles.titleImage}
                content={props.titleImage}
              />
            ) : null}
            <h2 className={styles.title}>
              <PoorText content={props.title} />
            </h2>
            <RichText
              element={'div'}
              className={styles.description}
              content={props.description}
            />
            <OptionalButton
              className={styles.btn}
              style={'air'}
              color={'white'}
              link={props.link}
            />
          </div>
          <RichText
            element={'div'}
            className={styles.tags}
            content={props.tags}
          />
        </div>
      </div>
    </div>
  );
};

export const CharacterBlocks = (props: ColoredBlockConent[]) => {
  const elems = props.map((item, i) => {
    return <CharacterBlock {...item} key={i} />;
  });
  return <FullWidthBlock bg={'black'}>{elems}</FullWidthBlock>;
};

import React from 'react';

import styles from './style.scss';
import { imgUrlToCss, objToCssStyle } from '../../../../utils/CssVar';
import { HistoryGridContent } from '../../../../generated/About';
import { FullWidthBlock } from '../../../general/fullWidthBlock/FullWidthBlock';
import {
  Picture,
  PoorText,
  RichText,
  useContentFromCMSRef,
} from '2mr-cms-frontend';

export const HistoryGrid = (props: HistoryGridContent) => {
  const { ref } = useContentFromCMSRef(props.backgroundImageBottom);
  const style = objToCssStyle({
    imageTop: imgUrlToCss(props.backgroundImageTop.value),
    imageBottom: imgUrlToCss(props.backgroundImageBottom.value),
  });
  const elems = props.items.map((item, i) => {
    return (
      <li className={styles.item} key={i}>
        <div className={styles.textWrapper}>
          <div className={styles.tag}>
            <PoorText content={item.tag} />
          </div>
          <div className={styles.title}>
            <PoorText content={item.title} />
          </div>
          <RichText
            element={'div'}
            content={item.description}
            className={styles.desc}
          />
        </div>
        <div className={styles.imgWrapper}>
          <Picture
            aria-hidden={true}
            className={styles.itemImg}
            content={item.image}
          />
        </div>
      </li>
    );
  });
  return (
    <FullWidthBlock bg={'black'}>
      <ul ref={ref} style={style} className={styles.historyGrid}>
        {elems}
      </ul>
    </FullWidthBlock>
  );
};

import styles from './style.scss';
import React from 'react';
import { ImageContent, PoorTextContent } from '../../../generated/StdLib';
import { clsx, Picture, PoorText } from '2mr-cms-frontend';

type Props = {
  label?: PoorTextContent;
  max?: number;
  value: number;
  iconUrl: ImageContent;
  className?: string;
};
export const Rating = (props: Props) => {
  const val = props.value;
  const max = props.max || val;
  const iconElems = [];

  for (let i = 1; i <= max; i++) {
    const cl = i <= val ? styles.filled : styles.unfilled;
    iconElems.push(
      <Picture
        aria-hidden={true}
        key={i}
        className={clsx(styles.icon, cl)}
        content={props.iconUrl}
      />
    );
  }

  return (
    <div className={clsx(props.className, styles.rating)}>
      <span className={styles.label}>
        {props.label && <PoorText content={props.label} />}
      </span>
      <span className={styles.icons}>{iconElems}</span>
    </div>
  );
};

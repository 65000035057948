import { typeDefiner } from '2mr-cms-typegen';
import {
  HasTitleAndDescription,
  HeaderCommon,
  ImageCardContent,
  ListCardContent,
} from './Common';
import { JsonObjectType, optional } from '2mr-cms-typegen';

const defineType = typeDefiner('EventContent.ts');

export const ScrollImageCardContent: JsonObjectType = {
  ...ImageCardContent,
  scrollIntoId: optional('string'),
};
defineType(ScrollImageCardContent, 'ScrollImageCardContent');

export const EventRolesContent: JsonObjectType = {
  title: 'text',
  description: optional('richText'),
  cards: [ScrollImageCardContent],
};
defineType(EventRolesContent, 'EventRolesContent');

export const SurvivorItemsContent: JsonObjectType = {
  ...ImageCardContent,
  level: 'number',
};
defineType(SurvivorItemsContent, 'SurvivorItemsContent');

export const ForSurvivorContent: JsonObjectType = {
  scrollId: 'string',
  title: 'richText',
  description: optional('richText'),
  backgroundUrl: 'image',
  backgroundColor: 'color',
  levels: ['text'],
  levelsIcons: 'image',
  grid: [SurvivorItemsContent],
};
defineType(ForSurvivorContent, 'ForSurvivorContent');

export const ForSpectatorContent: JsonObjectType = {
  scrollId: 'string',
  title: 'richText',
  description: optional('richText'),
  backgroundUrl: 'image',
  backgroundColor: 'color',
  grid: [ImageCardContent],
};
defineType(ForSpectatorContent, 'ForSpectatorContent');

export const EventHanbookContent: JsonObjectType = {
  ...HasTitleAndDescription,
  cards: [ListCardContent],
};
defineType(EventHanbookContent, 'EventHanbookContent');
export const EventContent: JsonObjectType = {
  header: HeaderCommon,
  roles: EventRolesContent,
  forSurvivor: ForSurvivorContent,
  forSpectaror: ForSpectatorContent,
  tips: ImageCardContent,
  handbook: EventHanbookContent,
};

defineType(EventContent, 'EventContent');
export const importEventContent = () => {};

import React from 'react';

import styles from './Header.scss';
import { imgUrlToCss, objToCssStyle } from '../../../utils/CssVar';
import { HeaderCommon } from '../../../generated/Common';
import { clsx, RichText, useContentFromCMSRef } from '2mr-cms-frontend';

type Props = HeaderCommon & {};

export const Header = (props: Props) => {
  const { ref } = useContentFromCMSRef(props.backgroundUrl);
  const style = objToCssStyle({
    headerBackgroundImageUrl: imgUrlToCss(props.backgroundUrl.value),
    backgroundColor: props.backgroundColor || '#000',
  });
  return (
    <div
      className={clsx(
        styles.header,
        styles[props.backgroundGradientDirection],
        styles[props.backgroundFit]
      )}
      style={style}
      ref={ref}
    >
      <div
        className={`${styles.content} ${
          props.titleAfterSubtitle ? styles.contentReverse : ''
        }`}
      >
        <h1 className={styles.title}>
          <RichText content={props.title} element={'span'} />
        </h1>
        <div className={styles.subTitle}>
          {props.subTitle && (
            <RichText content={props.subTitle} element={'span'} />
          )}
        </div>
      </div>
    </div>
  );
};

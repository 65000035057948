import React from 'react';

import { Header } from '../component/general/header/Header';
import { EventContent } from '../generated/EventContent';
import { EventSurvivorBlock } from '../component/pages/event/eventSurvivorBlock/EventSurvivorBlock';
import { EventSpectatorBlock } from '../component/pages/event/eventSpectatorBlock/EventSpectatorBlock';
import { TwoCardsScrollBlock } from '../component/general/twoCardsScrollBlock/TwoCardsScrollBlock';
import { EventTipsBlock } from '../component/pages/event/eventTipsBlock/EventTipsBlock';
import { EventHandbookBlock } from '../component/pages/event/eventHandbookBlock/EventHandbookBlock';

type Props = {
  content: EventContent;
};

export const EventPage = ({ content }: Props) => {
  return (
    <>
      <Header {...content.header} />
      <TwoCardsScrollBlock {...content.roles} />
      <EventSurvivorBlock {...content.forSurvivor} />
      <EventSpectatorBlock {...content.forSpectaror} />
      <EventTipsBlock {...content.tips} />
      <EventHandbookBlock {...content.handbook} />
    </>
  );
};

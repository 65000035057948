import React from 'react';
import { Header } from '../component/general/header/Header';
import { PricingContent } from '../generated/PricingContent';
import { PackagesBlock } from '../component/pages/pricing/priceCard/PackagesBlock';
import { GroupPackage } from '../component/pages/pricing/groupPackage/GroupPackage';
import { SurvivorKit } from '../component/pages/pricing/survivorKit/SurvivorKit';

type Props = {
  content: PricingContent;
};

export const PricingPage = ({ content }: Props) => {
  return (
    <>
      <Header {...content.header} />
      <PackagesBlock {...content.packages} />
      <GroupPackage {...content.groupTicket} />
      <SurvivorKit {...content.survivorKit} />
    </>
  );
};

import React from 'react';
import styles from './style.scss';
import { FullWidthBlock } from '../../../general/fullWidthBlock/FullWidthBlock';
import { Comrades } from '../../home/comrades/Comrades';
import { ComradesContent } from '../../../../generated/Home';

interface Props {
  comrades: ComradesContent;
  news: ComradesContent;
  id: string;
}

export const PromoPartnerGrids = ({ comrades, news, id }: Props) => (
  <FullWidthBlock
    id={id}
    className={styles.redBlackWrapper}
    bg={'red-black-vertical'}
  >
    <Comrades {...comrades} />
    <Comrades {...news} />
  </FullWidthBlock>
);

import React from 'react';
import { PromoContent } from '../generated/Promo';
import {
  ForSpectatorContent,
  ForSurvivorContent,
} from '../generated/EventContent';
import { ComradesContent, PhotosDecorativeContent } from '../generated/Home';
import { SocialNetwork } from '../generated/Common';
import { ContactFormBlockContent } from '../generated/ContactContent';
import { PromoHeader } from '../component/pages/promo/header/PromoHeader';
import { PromoWhatIs } from '../component/pages/promo/whatIs/PromoWhatIs';
import { PromoObstacles } from '../component/pages/promo/obstacles/PromoObstacles';
import { PromoAfterParty } from '../component/pages/promo/afterParty/PromoAfterParty';
import { PhotosDecorative } from '../component/general/photosDecorative/PhotosDecorative';
import { PromoCommunity } from '../component/pages/promo/joinCommunity/PromoCommunity';
import { PromoPartnerGrids } from '../component/pages/promo/partnerGrids/PromoPartnerGrids';
import { PromoContactUs } from '../component/pages/promo/contactUs/PromoContactUs';

export type PromoPageAddons = {
  obstacles: ForSurvivorContent;
  afterParty: ForSpectatorContent;
  decorativePhotos: PhotosDecorativeContent;
  joinCommunity: SocialNetwork[];
  comrades: ComradesContent;
  outbreakNews: ComradesContent;
  contactUs: ContactFormBlockContent;
};

type Props = {
  promo: PromoContent;
  addons: PromoPageAddons;
};

export const PromoPage = ({ promo, addons }: Props) => (
  <>
    <PromoHeader {...promo.promoHeader} />
    <PromoWhatIs {...promo.whatIsRFYL} />
    {/*<PromoTickets {...promo.tickets} />*/}
    <PromoObstacles {...promo.obstacles} {...addons.obstacles} />
    <PromoAfterParty {...promo.afterParty} {...addons.afterParty} />
    <PhotosDecorative
      {...promo.decorativePhotos}
      {...addons.decorativePhotos}
    />
    <PromoCommunity {...promo.joinCommunity} socials={addons.joinCommunity} />
    <PromoPartnerGrids
      {...promo.comrades}
      comrades={addons.comrades}
      news={addons.outbreakNews}
    />
    <PromoContactUs {...promo.contactUs} {...addons.contactUs} />
  </>
);

import styles from './style.scss';
import React from 'react';
import { FullWidthBlock } from '../../../general/fullWidthBlock/FullWidthBlock';
import { HowToGetContent } from '../../../../generated/Home';
import { TitleDescription } from '../../../general/titleDescription/TitleDescription';
import { Picture, PoorText, RichText } from '2mr-cms-frontend';

export const HomeHowToGet = (props: HowToGetContent) => {
  const list = props.steps.map((st, i) => {
    return (
      <li key={i} className={styles.item}>
        <div className={styles.counter}>{i + 1}</div>
        <Picture
          aria-hidden={true}
          className={styles.itemIcon}
          content={st.image}
        />
        <div className={styles.itemText}>
          <div className={styles.itemTitle}>
            <PoorText content={st.title} />
          </div>
          <RichText
            className={styles.itemDesc}
            element={'div'}
            content={st.description}
          />
        </div>
      </li>
    );
  });
  return (
    <FullWidthBlock bg={'gray'}>
      <div className={styles.container}>
        <TitleDescription title={props.title} description={props.description} />
        <div className={styles.grid}>
          <Picture
            aria-hidden={true}
            className={styles.rightImage}
            content={props.image}
          />
          <ul className={styles.list}>{list}</ul>
        </div>
      </div>
    </FullWidthBlock>
  );
};

import React from 'react';

import styles from './style.scss';
import { imgUrlToCss, objToCssStyle } from '../../../../utils/CssVar';
import {
  Color,
  ImageContent,
  RichTextContent,
} from '../../../../generated/StdLib';
import { RichText, useContentFromCMSRef } from '2mr-cms-frontend';

type Props = {
  scrollId?: string;
  title: RichTextContent;
  description?: RichTextContent;
  backgroundUrl: ImageContent;
  backgroundColor: Color;
};

export const EventRole = (props: Props) => {
  const { ref } = useContentFromCMSRef(props.backgroundUrl);
  const style = objToCssStyle({
    headerBackgroundImageUrl: imgUrlToCss(props.backgroundUrl.value),
    backgroundColor: props.backgroundColor || '#000',
  });
  return (
    <div
      className={styles.header}
      style={style}
      ref={ref}
      id={props.scrollId || ''}
    >
      <div className={styles.content}>
        <div className={styles.leftText}>
          <h3 className={styles.title}>
            <RichText content={props.title} element={'span'} />
          </h3>
          <div className={styles.subTitle}>
            <RichText content={props.description} element={'span'} />
          </div>
        </div>
      </div>
    </div>
  );
};

import styles from './mapTooltipStyle.scss';
import React, { ReactNode } from 'react';
import { InteractiveMapItem } from '../../../generated/About';
import { imgUrlCssVar } from '../../../utils/CssVar';
import { DateCell } from './MapDataCells';
import { ImageContent, PoorTextContent } from '../../../generated/StdLib';
import {
  clsx,
  Picture,
  PoorText,
  useContentFromCMSRef,
} from '2mr-cms-frontend';

function getTooltipCoords(target?: Element, w = 650, h = 216) {
  if (!target) {
    return {};
  }
  const pad = 5;
  const rect = target.getBoundingClientRect();
  const coords = {
    top: rect.y + window.scrollY - h - pad + 'px',
  };
  if (rect.x + w < document.body.clientWidth) {
    //top right
    return {
      ...coords,
      left: rect.x + rect.width + pad + 'px',
    };
  }
  if (rect.x - w > 0) {
    //top left
    return {
      ...coords,
      left: rect.x - w - pad + 'px',
    };
  }
  //bottom center
  return {
    top: rect.y + window.scrollY + rect.height + pad + 'px',
    left: `calc(50% - ${w / 2}px)`,
  };
}

type Props = {
  headers: {
    outbreakIcon: ImageContent;
    outbreakHeader: PoorTextContent;
    survivorsIcon: ImageContent;
    survivorsHeader: PoorTextContent;
  };
  list: {
    [p: string]: InteractiveMapItem[];
  };
  selected?: number;
  target?: Element;
};
export const MapTooltipCard = (props: Props) => {
  let elems: ReactNode[] = [];
  const selected = props.selected;
  if (selected) {
    const list = props.list[selected];
    if (!list || list.length === 0) {
      return null;
    }
    const tableHeader = (
      <tr className={styles.tableHeader}>
        <th>
          <PoorText content={props.headers.outbreakHeader} />
        </th>
        <th>
          <PoorText content={props.headers.survivorsHeader} />
        </th>
      </tr>
    );

    elems = list.map((card) => {
      const tableRows = card.events.map((ev, i) => {
        return (
          <tr key={i}>
            <td className={styles.outbreakCell}>
              <DateCell from={ev.outbreakBegin} to={ev.outbreakEnd} />
            </td>
            <td className={styles.survivorsCell}>
              <PoorText content={ev.survivors} />
            </td>
          </tr>
        );
      });
      return (
        <div className={styles.singleCard}>
          <Picture
            aria-hidden={true}
            content={card.image}
            className={styles.cardImage}
          />
          <div className={styles.cardText}>
            <div>
              <div className={styles.cardCountry}>
                <PoorText content={card.country} />
              </div>
              <div className={styles.cardCity}>
                <PoorText content={card.city} />
              </div>
            </div>
            <table className={styles.cardTable}>
              {tableHeader}
              {tableRows}
            </table>
          </div>
        </div>
      );
    });
  }

  const { ref } = useContentFromCMSRef(props.headers.outbreakIcon);
  const style = {
    ...getTooltipCoords(props.target),
    ...imgUrlCssVar('--outbreak-icon', props.headers.outbreakIcon.value),
    ...imgUrlCssVar('--survivor-icon', props.headers.survivorsIcon.value),
  };
  return (
    <div
      aria-hidden={true}
      className={clsx(styles.tooltipBox, !props.selected ? styles.hide : '')}
      ref={ref}
      style={style}
    >
      {elems}
    </div>
  );
};

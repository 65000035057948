import React from 'react';
import styles from './style.scss';
import { FullWidthBlock } from '../../../general/fullWidthBlock/FullWidthBlock';
import { ImageCardContent, SocialNetwork } from '../../../../generated/Common';
import { RichText } from '2mr-cms-frontend';
import { SocialNetworks } from '../../../inline/socialNetworks/SocialNetworks';

type Props = ImageCardContent & { socials: SocialNetwork[] };

export const PromoCommunity = ({
  title,
  description,
  image,
  socials,
}: Props) => (
  <FullWidthBlock bg='black' className={styles.joinCommunity}>
    <div className={styles.bgImageWrapper}>
      <img src={image.value} className={styles.bgImage} />
    </div>
    <div className={styles.content}>
      <RichText content={title} element='div' className={styles.title} />
      <RichText
        content={description}
        element='p'
        className={styles.description}
      />
      <SocialNetworks socials={socials} color={'light'} />
    </div>
  </FullWidthBlock>
);

import React from 'react';

import styles from './style.scss';
import { FullWidthBlock } from '../../../general/fullWidthBlock/FullWidthBlock';
import { ImageCardContent } from '../../../../generated/Common';
import { Picture, PoorText, RichText } from '2mr-cms-frontend';

export const EventTipsBlock = (props: ImageCardContent) => {
  return (
    <FullWidthBlock bg={'dark-gray'}>
      <div className={styles.container}>
        <div className={styles.textWrapper}>
          <h2 className={styles.title}>
            <PoorText content={props.title} />
          </h2>
          <RichText
            element={'div'}
            content={props.description}
            className={styles.desc}
          />
        </div>
        <div className={styles.imgWrapper}>
          <Picture
            aria-hidden={true}
            className={styles.itemImg}
            content={props.image}
          />
        </div>
      </div>
    </FullWidthBlock>
  );
};

import styles from './style.scss';
import React, { useEffect, useRef, useState } from 'react';
import { InteractiveMapBlock } from '../../../generated/About';
import { FullWidthBlock } from '../fullWidthBlock/FullWidthBlock';
import { MapTooltipCard } from './MapTooltipCard';
import { imgUrlToCss, objToCssStyle } from '../../../utils/CssVar';
import { PoorText, RichText } from '2mr-cms-frontend';

export const InteractiveMap = (props: InteractiveMapBlock) => {
  const [hovered, setHovered] = useState<number | undefined>();
  const ref = useRef<HTMLDivElement>(null);

  function getTarget(index?: number) {
    if (!ref.current || !index) {
      return;
    }
    const els = ref.current.querySelectorAll('*');
    return els[index];
  }

  useEffect(() => {
    if (!ref.current) {
      return;
    }
    const indices = Object.keys(props.map.data).map((ind) => {
      return parseInt(ind, 10);
    });
    const els = ref.current.querySelectorAll('*');
    const unsubscribe: Array<any> = [];

    indices.forEach((ind) => {
      const el = els[ind];
      if (!el) {
        return;
      }
      el.classList.add(styles.redDot);

      const mouseover = () => {
        setHovered(ind);
      };
      const mouseout = () => {
        setHovered(undefined);
      };

      el.addEventListener('mouseover', mouseover);
      el.addEventListener('mouseout', mouseout);
      unsubscribe.push(() => {
        el.removeEventListener('mouseover', mouseover);
        el.removeEventListener('mouseout', mouseout);
      });
    });

    return () => {
      unsubscribe.forEach((func) => {
        func();
      });
    };
  }, [ref, ref.current, props.map, setHovered]);

  const style = objToCssStyle({
    cursor: imgUrlToCss(props.cursor?.value),
  });
  return (
    <FullWidthBlock bg={'black'} style={style}>
      <div className={styles.titleBlock}>
        <RichText
          className={styles.supTitle}
          element={'div'}
          content={props.superTitle}
        />
        <h1 className={styles.title}>
          <PoorText content={props.title} />
        </h1>
        <RichText
          className={styles.subTitle}
          element={'div'}
          content={props.subTitle}
        />
      </div>
      <div className={styles.mapContainer}>
        <div
          className={styles.svgWrapper}
          dangerouslySetInnerHTML={{ __html: props.map.svg }}
          ref={ref}
        />

        <MapTooltipCard
          headers={props.tableHeaders}
          selected={hovered}
          list={props.map.data}
          target={getTarget(hovered)}
        />
      </div>
    </FullWidthBlock>
  );
};

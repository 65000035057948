import React, { useEffect, useState } from 'react';
import { Header } from '../component/general/header/Header';
import { NewsHeaderContent } from '../generated/NewsHeaderContent';
import { Button } from '../component/inline/button/Button';
import { NewsArticlePreviewContent } from '../generated/NewsContent';
import { fetchNewsList } from '../utils/fetch';
import { RecentNewsBlock } from '../component/general/recentNewsBlock/RecentNewsBlock';
import { prepareArticlePreview } from '../component/general/recentNewsBlockSmart/RecentNewsBlockSmart';
import { PoorText } from '2mr-cms-frontend';

type Props = {
  content: NewsHeaderContent;
};
const PAGE_SIZE = 10;
export const NewsPage = ({ content }: Props) => {
  const [list, setList] = useState<NewsArticlePreviewContent[]>([]);
  const [offset, setOffset] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [loadedAll, setLoadedAll] = useState(false);

  function fetchNews(off = offset) {
    setIsLoading(true);

    fetchNewsList(off, PAGE_SIZE)
      .then((r: NewsArticlePreviewContent[]) => {
        setList((l) => {
          const newArticles: NewsArticlePreviewContent[] = r.map((v) =>
            prepareArticlePreview(v)
          );
          return [...l, ...newArticles];
        });
        setOffset((o) => {
          return o + PAGE_SIZE;
        });
        if (r.length < PAGE_SIZE) {
          setLoadedAll(true);
        }
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    setOffset(0);
    setLoadedAll(false);
    setList([]);

    fetchNews(0);
  }, []);

  const btn = (
    <Button
      disabled={isLoading}
      style={'air'}
      onClick={() => {
        fetchNews();
      }}
    >
      {content.loadMoreButtonLabel ? (
        <PoorText content={content.loadMoreButtonLabel} />
      ) : (
        'Load more'
      )}
    </Button>
  );
  return (
    <>
      <Header {...content.header} />
      <RecentNewsBlock
        title={content.newsHeader}
        newsList={list}
        buttonLink={loadedAll ? null : btn}
      />
    </>
  );
};

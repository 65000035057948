import { HomeContent } from './Home';
import { FooterContent, NavigationContent } from './Navigation';
import { CharactersContent } from './Characters';
import { AboutContent } from './About';
import { PricingContent } from './Pricing';
import { EventContent } from './Event';
import { NewsHeaderContent } from './NewsHeader';
import { ContactContent } from './Contact';
import { NewsContent } from './News';
import { PromoContent } from './Promo';
import { PromoNavigationContent } from './PromoNavigation';

export const contentsAndIds = [
  {
    id: 'promoNavigation',
    definition: PromoNavigationContent,
  },
  {
    id: 'promo',
    definition: PromoContent,
  },
  {
    id: 'navigation',
    definition: NavigationContent,
  },
  {
    id: 'footer',
    definition: FooterContent,
  },
  {
    id: 'home',
    definition: HomeContent,
  },
  {
    id: 'about',
    definition: AboutContent,
  },
  {
    id: 'event',
    definition: EventContent,
  },
  {
    id: 'characters',
    definition: CharactersContent,
  },
  {
    id: 'newsHeader',
    definition: NewsHeaderContent,
  },
  {
    id: 'pricing',
    definition: PricingContent,
  },
  {
    id: 'contact',
    definition: ContactContent,
  },
  {
    id: 'news',
    definition: NewsContent,
  },
];

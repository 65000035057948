import React from 'react';

import styles from './Button.scss';
import { Link } from 'react-router-dom';
import { clsx } from '2mr-cms-frontend';

export type ButtonProps = {
  children?: React.ReactNode;
  takeAllWidth?: boolean;
  className?: string;
  style?: 'filled' | 'air';
  color?: 'accent' | 'red' | 'blue' | 'black' | 'white';
} & (
  | { href: string; disabled?: boolean; targetBlank?: boolean }
  | { onClick: () => void; disabled?: boolean; isSubmit?: false }
  | { onClick: () => void; disabled?: boolean; isSubmit: true; value?: string }
);

function isAbsoluteUrl(urlToTest: string) {
  return (
    new URL(document.baseURI).origin !==
    new URL(urlToTest, document.baseURI).origin
  );
}

export const Button = (props: ButtonProps) => {
  const {
    children,
    takeAllWidth,
    style = 'air',
    color = 'black',
    className: externalClassName,
  } = props;

  const className = clsx(
    styles.button,
    takeAllWidth && styles.takeAllWidth,

    style === 'filled' && styles.filled,
    style === 'air' && styles.air,

    color === 'accent' && styles.accent,
    color === 'red' && styles.red,
    color === 'blue' && styles.blue,
    color === 'black' && styles.black,
    color === 'white' && styles.white,

    externalClassName
  );

  if ('href' in props && props.disabled) {
    return (
      <button className={className} disabled={true}>
        {children}
      </button>
    );
  }

  if ('href' in props) {
    if (isAbsoluteUrl(props.href) || props.href.indexOf('mailto:') === 0) {
      return (
        <a
          className={className}
          href={props.href}
          target={props.targetBlank ? '_blank' : undefined}
        >
          {children}
        </a>
      );
    }
    return (
      <Link className={className} to={props.href}>
        {children}
      </Link>
    );
  } else {
    if (props.isSubmit) {
      return (
        <input
          type={'submit'}
          value={props.value}
          className={className}
          disabled={props.disabled}
          onClick={props.onClick}
        />
      );
    }
    return (
      <button
        className={className}
        onClick={props.onClick}
        disabled={props.disabled}
      >
        {children}
      </button>
    );
  }
};

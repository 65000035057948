export const HOME_PAGE_URL = '/';
export const ABOUT_PAGE_URL = '/about';
export const EVENT_PAGE_URL = '/event';
export const CHARACTERS_PAGE_URL = '/characters';
export const NEWS_PAGE_URL = '/news';
export const PRICING_PAGE_URL = '/pricing';
export const CONTACT_PAGE_URL = '/contact';

export const NEWS_URL = '/news';

export const NEWS_SINGLE_BASE_URL = '/post/';
export const NEWS_SINGLE_URL = NEWS_SINGLE_BASE_URL + ':slug';

import React from 'react';

import styles from './style.scss';
import { FullWidthBlock } from '../fullWidthBlock/FullWidthBlock';
import { TitleDescription } from '../titleDescription/TitleDescription';
import { ImageCardHorizontal } from '../ImageCard/ImageCardHorizontal';
import {
  ImageContent,
  Optional,
  PoorTextContent,
  RichTextContent,
} from '../../../generated/StdLib';
import { HasTitleAndUrl } from '../../../generated/Common';

type CardContent = {
  title: PoorTextContent;
  description: RichTextContent;
  image: ImageContent;
  scrollIntoId?: Optional<string>;
  link?: HasTitleAndUrl;
};
export type EventRolesBlockContent = {
  title: PoorTextContent;
  description: Optional<RichTextContent>;
  cards: CardContent[];
};

export const TwoCardsScrollBlock = (props: EventRolesBlockContent) => {
  const cards = props.cards.map((item, i) => {
    if (item.scrollIntoId) {
      const onClick = () => {
        const elem = document.getElementById(item.scrollIntoId!);
        if (elem) {
          elem.scrollIntoView({ behavior: 'smooth' });
        }
      };
      return <ImageCardHorizontal key={i} {...item} onClick={onClick} />;
    }
    return <ImageCardHorizontal key={i} {...item} />;
  });
  return (
    <FullWidthBlock bg={'gray-light'}>
      <div className={styles.container}>
        <TitleDescription title={props.title} description={props.description} />
        <div className={styles.grid}>{cards}</div>
      </div>
    </FullWidthBlock>
  );
};

import React from 'react';

import styles from './style.scss';
import { imgUrlToCss, objToCssStyle } from '../../../utils/CssVar';
import { Button } from '../../inline/button/Button';
import { HeaderSliderSlideContent } from '../../../generated/Home';
import {
  clsx,
  PoorText,
  RichText,
  useContentFromCMSRef,
} from '2mr-cms-frontend';

export const HeaderSlide = (props: HeaderSliderSlideContent) => {
  const { ref } = useContentFromCMSRef(props.image);
  const style = objToCssStyle({
    headerBackgroundImageUrl: imgUrlToCss(props.image.value),
    backgroundColor: props.backgroundColor,
  });
  return (
    <div
      className={clsx(
        styles.headerSlide,
        styles[props.backgroundGradientDirection],
        styles[props.backgroundFit]
      )}
      style={style}
      ref={ref}
    >
      <div className={styles.content}>
        <div className={styles.textContent}>
          <div className={styles.title}>
            <RichText content={props.title} element={'span'} />
          </div>
          <div className={styles.subTitle}>
            <RichText content={props.description} element={'span'} />
          </div>
          <Button
            style={'air'}
            color={'white'}
            href={props.link.url}
            targetBlank={props.link.targetBlank}
          >
            <PoorText content={props.link.title} />
          </Button>
        </div>
      </div>
    </div>
  );
};

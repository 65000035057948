import React from 'react';

import styles from './style.scss';
import { FullWidthBlock } from '../../../general/fullWidthBlock/FullWidthBlock';
import { EventHanbookContent } from '../../../../generated/EventContent';
import { ListCard } from '../../../general/listCard/ListCard';
import { PoorText, RichText } from '2mr-cms-frontend';

export const EventHandbookBlock = (props: EventHanbookContent) => {
  const cards = props.cards.map((c, i) => {
    return <ListCard {...c} key={i} />;
  });
  return (
    <FullWidthBlock bg={'dark-gray'}>
      <div className={styles.container}>
        <div className={styles.textWrapper}>
          <h2 className={styles.title}>
            <PoorText content={props.title} />
          </h2>
          <RichText
            element={'div'}
            content={props.description}
            className={styles.desc}
          />
        </div>
        <div className={styles.grid}>{cards}</div>
      </div>
    </FullWidthBlock>
  );
};

import styles from './style.scss';
import React from 'react';
import { ThreeImageCardsContent } from '../../../../generated/Common';

import { FullWidthBlock } from '../../../general/fullWidthBlock/FullWidthBlock';
import { TitleDescription } from '../../../general/titleDescription/TitleDescription';
import { Picture, PoorText, RichText } from '2mr-cms-frontend';

export const AboutOurEvolution = (props: ThreeImageCardsContent) => {
  const cardElems = props.cards.map((c, i) => {
    return (
      <li className={styles.card} key={i}>
        <Picture
          aria-hidden={true}
          className={styles.cardImage}
          content={c.image}
        />
        <div className={styles.cardTitle}>
          <PoorText content={c.title} />
        </div>
        <RichText
          element={'div'}
          className={styles.cardDesc}
          content={c.description}
        />
      </li>
    );
  });
  return (
    <FullWidthBlock bg={'white'}>
      <div className={styles.container}>
        <TitleDescription title={props.title} description={props.description} />
        <ul className={styles.list}>{cardElems}</ul>
      </div>
    </FullWidthBlock>
  );
};

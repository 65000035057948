import React from 'react';
import { Autoplay, EffectFade, Pagination, Swiper as ISwiper } from 'swiper';
import { Swiper } from 'swiper/react';

export interface SliderState {
  pagination: {
    current: number;
    total: number;
  };
  navigation: {
    left: boolean;
    right: boolean;
  };
  ref: ISwiper | undefined;
}

interface Props {
  children: React.ReactNode;
  setState: (setter: (prev: SliderState) => SliderState) => void;
  className?: string;
  speed: number;
  duration: number;
}

export const SliderFade = ({ children, setState, speed, duration }: Props) => {
  const initialize = (swiper: ISwiper) => {
    setState((prev) => ({
      ...prev,
      ref: swiper,
      navigation: { left: swiper.isBeginning, right: swiper.isEnd },
    }));
  };

  const handlePaginationChange = (
    swiper: ISwiper,
    current: number,
    total: number
  ) => {
    setState(() => ({
      ref: swiper,
      navigation: { left: swiper.isBeginning, right: swiper.isEnd },
      pagination: { current, total },
    }));
  };

  return (
    <Swiper
      modules={[Pagination, EffectFade, Autoplay]}
      spaceBetween={0}
      slidesPerView={1}
      effect={'fade'}
      fadeEffect={{ crossFade: true }}
      loop={false}
      slideToClickedSlide={false}
      speed={speed}
      autoplay={{
        delay: duration,
        disableOnInteraction: false,
        waitForTransition: false,
      }}
      pagination={{
        type: 'custom',
        renderCustom: (swiper, current, total) => {
          handlePaginationChange(swiper, current, total);
        },
      }}
      onSwiper={(swiper) => initialize(swiper)}
    >
      {children}
    </Swiper>
  );
};

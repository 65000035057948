import React, { useMemo } from 'react';
import styles from './SliderPaginationLine.scss';
import { objToCssStyle } from '../../../../utils/CssVar';
import { clsx } from '2mr-cms-frontend';

type PaginationProps = {
  current: number;
  total: number;
  onClick: (index: number) => void;
  className?: string;
  speed: number;
  disableAnimation?: boolean;
};

export const SliderPaginationLine = ({
  current,
  total,
  onClick,
  className,
  speed,
  disableAnimation,
}: PaginationProps) => {
  const bullets = useMemo(() => {
    return [...Array(total)];
  }, [total]);
  const style = objToCssStyle({
    speed: `${speed / 1000}s`,
  });

  return (
    <div
      className={clsx(
        className,
        styles.pagination,
        disableAnimation ? styles.disableAnim : ''
      )}
      style={style}
    >
      {bullets.map((_, i) => (
        <div
          className={clsx(
            styles.bullet,
            current === i + 1 && styles.bulletActive
          )}
          key={i}
          onClick={() => onClick(i)}
        />
      ))}
    </div>
  );
};

import React, { ReactNode } from 'react';
import styles from './style.scss';

type ThreeColumnCardsProps = {
  cards: ReactNode[];
};

export const ThreeColumnCards = (props: ThreeColumnCardsProps) => {
  const elems = props.cards.map((cardContent, i) => {
    return (
      <div className={styles.card} key={i}>
        {cardContent}
      </div>
    );
  });
  return <div className={styles.list}>{elems}</div>;
};

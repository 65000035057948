export function fetchNewsList(offset = 0, count = 4, tag?: string) {
  const baseURl = window.location.origin;
  const url = new URL('/getNews', baseURl);
  if (tag) {
    url.searchParams.append('key', 'tags');
    url.searchParams.append('value', encodeURIComponent(tag));
  }
  url.searchParams.append('offset', offset.toString());
  url.searchParams.append('count', count.toString());

  return fetch(url)
    .then((r) => {
      return r.json();
    })
    .catch((e) => {
      console.error(e);
    });
}

export function fetchNewsSingle(slugId: string) {
  const baseURl = window.location.origin;
  const url = new URL('/getNews', baseURl);
  url.searchParams.append('key', 'slug');
  url.searchParams.append('value', slugId.toString());

  url.searchParams.append('enrich', '1');
  url.searchParams.append('full', '1');

  return fetch(url).then((r) => {
    return r.json();
  });
}

import styles from './NavPanel.scss';
import React from 'react';
import { imgUrlCssVar } from '../../../../utils/CssVar';
import { NavLink } from '../navLink/NavLink';
import { Link } from 'react-router-dom';
import { NavigationContent } from '../../../../generated/Navigation';
import { HOME_PAGE_URL } from '../../../../data/Urls';
import { HeaderBar } from '../headerBar/headerBar';
import { useContentFromCMSRef } from '2mr-cms-frontend';
import { PromoNavigationContent } from '../../../../generated/PromoNavigation';

export type NavPanelProps = NavigationContent &
  PromoNavigationContent & {
    onOpenMenu: () => void;
    promo: boolean;
  };

export const NavPanel = ({
  promo,
  ticketBar,
  sections,
  promoSections,
  smallLogoUrl,
  menuIconUrl,
  onOpenMenu,
}: NavPanelProps) => {
  //TODO:: highlight active navLink based on scroll

  const { ref: ref1 } = useContentFromCMSRef(smallLogoUrl);
  const { ref: ref2 } = useContentFromCMSRef(smallLogoUrl);

  return (
    <nav className={styles.panel}>
      {ticketBar && <HeaderBar {...ticketBar} />}
      <div className={styles.content}>
        <Link
          ref={ref1}
          to={HOME_PAGE_URL}
          className={styles.logo}
          style={imgUrlCssVar('--nav-logo-url', smallLogoUrl.value)}
        />
        <div className={styles.sections}>
          {(promo ? promoSections : sections).map((s, idx) => (
            <NavLink title={s.title} href={s.url} opaq={true} key={idx} />
          ))}
        </div>
        {!promo && (
          <button
            ref={ref2}
            className={styles.menu}
            style={imgUrlCssVar('--nav-menu-icon-url', menuIconUrl.value)}
            onClick={onOpenMenu}
          />
        )}
      </div>
    </nav>
  );
};

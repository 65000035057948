import { typeDefiner } from '2mr-cms-typegen';
import { HeaderCommon } from './Common';
import { JsonObjectType, optional } from '2mr-cms-typegen';

const defineType = typeDefiner('NewsHeaderContent.ts');

export const NewsHeaderContent: JsonObjectType = {
  header: HeaderCommon,
  loadMoreButtonLabel: 'text',
  readNextHeader: 'text',
  newsHeader: optional('text'),
};
defineType(NewsHeaderContent, 'NewsHeaderContent');
export const importNewsHeaderContent = () => {};

import React from 'react';

import styles from './Footer.scss';
import { imgUrlCssVar } from '../../../utils/CssVar';
import { NavLink } from '../navigation/navLink/NavLink';
import { SocialNetworks } from '../../inline/socialNetworks/SocialNetworks';
import {
  FooterContent,
  NavigationContent,
} from '../../../generated/Navigation';
import { PoorText, useContentFromCMSRef } from '2mr-cms-frontend';
import { PromoNavigationContent } from '../../../generated/PromoNavigation';

type Props = FooterContent &
  NavigationContent &
  PromoNavigationContent & { promo: boolean };

export const Footer = ({
  companyName,
  footerFullLogoUrl,
  socials,
  sections,
  promoSections,
  promo,
}: Props) => {
  const { ref } = useContentFromCMSRef(footerFullLogoUrl);
  return (
    <footer className={styles.footer}>
      <div className={styles.content}>
        <div className={styles.grid}>
          <div
            className={styles.logo}
            ref={ref}
            style={imgUrlCssVar('--footer-logo-url', footerFullLogoUrl.value)}
          />
          <div className={styles.nav}>
            {(promo ? promoSections : sections).map((s, i) => (
              <NavLink opaq={true} key={i} title={s.title} href={s.url} />
            ))}
          </div>
          <div className={styles.socials}>
            <SocialNetworks socials={socials} color={'light'} />
          </div>
          <div className={styles.copyright}>
            © {new Date().getFullYear()} <PoorText content={companyName} />
          </div>
        </div>
      </div>
    </footer>
  );
};

import { JsonObjectType, optional, typeDefiner } from '2mr-cms-typegen';
import { HasTitleAndDescription, HasTitleAndUrl, HeaderCommon } from './Common';

const defineType = typeDefiner('Characters.ts');

export const ColoredBlockConent: JsonObjectType = {
  ...HasTitleAndDescription,
  titleImage: optional('image'),
  backgroundImage: 'image',
  backgroundColor: 'color',
  tags: 'richText',
  link: optional(HasTitleAndUrl),
};
defineType(ColoredBlockConent, 'ColoredBlockConent');

export const CharactersContent: JsonObjectType = {
  header: HeaderCommon,
  charactersList: [ColoredBlockConent],
};
defineType(CharactersContent, 'CharactersContent');
export const importCharactersContent = () => {};

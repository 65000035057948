import React from 'react';

export function imgUrlToCss(value: string | undefined): string {
  return `url('${value}')`;
}
export function imgUrlCssVar(key: string, value: string): React.CSSProperties {
  const res: any = {};
  res[key] = imgUrlToCss(value);
  return res as React.CSSProperties;
}

type stringMap = {
  [index: string]: string | number | undefined;
};

const kebabize = (str: string) => {
  return str
    .split('')
    .map((letter, idx) => {
      return letter.toUpperCase() === letter
        ? `${idx !== 0 ? '-' : ''}${letter.toLowerCase()}`
        : letter;
    })
    .join('');
};
export function objToCssStyle(obj: stringMap): React.CSSProperties {
  const res: any = {};
  Object.entries(obj).forEach(([k, v]) => {
    if (v === undefined) {
      return;
    }
    res[`--${kebabize(k)}`] = v;
  });

  return res as React.CSSProperties;
}

export function splitTime(timeMs: number) {
  const a = Math.floor(timeMs / 1000);
  const secInMinute = 60;
  const secInHour = secInMinute * 60;
  const secInDay = secInHour * 24;
  const daysRest = a % secInDay;
  const days = (a - daysRest) / secInDay;
  const hoursRest = daysRest % secInHour;
  const hours = (daysRest - hoursRest) / secInHour;
  const minRest = hoursRest % secInMinute;
  const minutes = (hoursRest - minRest) / secInMinute;
  const seconds = minRest;
  return {
    days,
    hours,
    minutes,
    seconds,
  };
}

export function formatDate(dateNumber: number) {
  return new Date(dateNumber).toLocaleString('en-US', {
    hour12: true,
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
  });
}

export function formatDateMonthYear(dateNumberStart: number) {
  return new Date(dateNumberStart).toLocaleString('en-US', {
    year: 'numeric',
    month: 'short',
  });
}

import React from 'react';

import { ContactContent } from '../generated/ContactContent';
import { TwoCardsScrollBlock } from '../component/general/twoCardsScrollBlock/TwoCardsScrollBlock';
import { ContactFormBlock } from '../component/pages/contactUs/form/Form';

type Props = {
  content: ContactContent;
};

export const ContactPage = ({ content }: Props) => {
  return (
    <>
      <ContactFormBlock {...content.formContent} />
      <TwoCardsScrollBlock
        {...content.getInvolved}
        cards={content.getInvolved.cards.map((card) => ({
          ...card,
          scrollIntoId: 'contact-us-form',
        }))}
      />
    </>
  );
};

import styles from './style.scss';
import React from 'react';
import { imgUrlCssVar } from '../../../../utils/CssVar';
import { ImageContent } from '../../../../generated/StdLib';
import { HasTitleAndUrl } from '../../../../generated/Common';
import { Button } from '../../../inline/button/Button';
import { FullWidthBlock } from '../../fullWidthBlock/FullWidthBlock';
import { PoorText, useContentFromCMSRef } from '2mr-cms-frontend';

export type HeaderBarProps = {
  smallLogoUrl?: ImageContent;
  title?: string;
  getTicketButton: HasTitleAndUrl;
};

export const HeaderBar = ({
  smallLogoUrl,
  getTicketButton,
  title,
}: HeaderBarProps) => {
  const styleTag = `#root{padding-top: calc(var(--rem-coeff) * 72);}`;
  const { ref } = useContentFromCMSRef(smallLogoUrl);

  return (
    <FullWidthBlock bg={'red-black-horizontal'}>
      <style>{styleTag}</style>
      <div className={styles.panel}>
        <div className={styles.left}>
          {smallLogoUrl && (
            <span
              ref={ref}
              className={styles.logo}
              style={imgUrlCssVar('--nav-logo-url', smallLogoUrl.value)}
            />
          )}
        </div>
        <div className={styles.center}>
          {title && <h2 className={styles.title}>{title}</h2>}
        </div>
        <div className={styles.right}>
          <Button
            className={styles.btn}
            style='filled'
            color={'red'}
            href={getTicketButton.url}
            targetBlank={true}
          >
            <PoorText content={getTicketButton.title} />
          </Button>
        </div>
      </div>
    </FullWidthBlock>
  );
};

import React from 'react';
import { Optional } from '../../../generated/StdLib';
import { HasTitleAndUrl } from '../../../generated/Common';
import { Button } from './Button';
import { PoorText } from '2mr-cms-frontend';

type Props = {
  takeAllWidth?: boolean;
  className?: string;
  style?: 'filled' | 'air';
  color?: 'accent' | 'red' | 'blue' | 'black' | 'white';
  link: Optional<HasTitleAndUrl>;
};
export const OptionalButton = (props: Props) => {
  if (!props.link) {
    return null;
  }

  return (
    <Button {...props} href={props.link.url}>
      <PoorText content={props.link.title} />
    </Button>
  );
};

import React from 'react';

import styles from './style.scss';
import { imgUrlCssVar } from '../../../utils/CssVar';
import { ListCardContent } from '../../../generated/Common';
import { PoorText, RichText, useContentFromCMSRef } from '2mr-cms-frontend';

export const ListCard = (c: ListCardContent) => {
  const points = c.list.map((item, i) => {
    return (
      <li key={i} className={styles.cardListItem}>
        <RichText
          element={'span'}
          content={item}
          className={styles.cardItemText}
        />
      </li>
    );
  });
  const { ref } = useContentFromCMSRef(c.listIcon);
  const style = imgUrlCssVar('--list-icon', c.listIcon.value);
  return (
    <div className={styles.card} ref={ref}>
      <h3 className={styles.cardTitle}>
        <PoorText content={c.title} />
      </h3>
      <ul className={styles.cardList} style={style}>
        {points}
      </ul>
    </div>
  );
};

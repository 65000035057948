import React from 'react';
import styles from './style.scss';
import { ComradesContent } from '../../../../generated/Home';
import { TitleDescription } from '../../../general/titleDescription/TitleDescription';
import { Picture } from '2mr-cms-frontend';

export const Comrades = (props: ComradesContent) => {
  const cards = props.list.map((item, i) => {
    return (
      <li key={i} className={styles.card}>
        {item.url ? (
          <a href={item.url} className={styles.cardLink}>
            <Picture
              aria-hidden={true}
              content={item.imageUrl}
              className={styles.cardImage}
            />
          </a>
        ) : (
          <span className={styles.cardLink}>
            <Picture
              aria-hidden={true}
              content={item.imageUrl}
              className={styles.cardImage}
            />
          </span>
        )}
      </li>
    );
  });
  return (
    <div className={styles.whiteBg}>
      <div className={styles.block}>
        <TitleDescription title={props.title} description={props.description} />
        <ul className={styles.list}>{cards}</ul>
      </div>
    </div>
  );
};

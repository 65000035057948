import React, { useState } from 'react';
import styles from './FeaturedSlider.scss';
import { SwiperSlide } from 'swiper/react';
import { SliderNavigation } from './navigation/SliderNavigation';
import { SliderPagination } from './pagination/SliderPagination';
import {
  DEFAULT_SLIDER_STATE,
  Slider,
  SliderState,
  useSliderControls,
} from './Slider';
import { ImageCardContent } from '../../../generated/Common';
import { ImageCard3 } from '../ImageCard/ImageCard3';

interface Props {
  slides: ImageCardContent[];
}

export const FeaturedSlider = ({ slides }: Props) => {
  const [state, setState] = useState<SliderState>(DEFAULT_SLIDER_STATE);

  const { handlePaginationClick, handleLeftClick, handleRightClick } =
    useSliderControls(state.ref);

  return (
    <div className={styles.featuredSlider}>
      <Slider
        setState={setState}
        className={styles.slider}
        spaceBetween={0}
        slidesPerView={'auto'}
      >
        {slides.map((slide, index) => (
          <SwiperSlide key={`slide-${index}`} className={styles.slide}>
            <ImageCard3 {...slide} />
          </SwiperSlide>
        ))}
      </Slider>
      {state.pagination.total > 1 && (
        <>
          <SliderPagination
            current={state.pagination.current}
            total={state.pagination.total}
            onClick={(index) => handlePaginationClick(index)}
            className={styles.pagination}
          />
          <SliderNavigation
            color='black'
            leftDisabled={state.navigation.left}
            rightDisabled={state.navigation.right}
            onLeftClick={handleLeftClick}
            onRightClick={handleRightClick}
          />
        </>
      )}
    </div>
  );
};

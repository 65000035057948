import React, { useMemo } from 'react';
import styles from './SliderPagination.scss';
import { clsx } from '2mr-cms-frontend';

type PaginationProps = {
  current: number;
  total: number;
  onClick: (index: number) => void;
  className?: string;
};

export const SliderPagination = ({
  current,
  total,
  onClick,
  className,
}: PaginationProps) => {
  const bullets = useMemo(() => {
    return [...Array(total)];
  }, [total]);

  return (
    <div className={className}>
      {bullets.map((_, i) => (
        <div
          className={clsx(
            styles.bullet,
            current === i + 1 && styles.bulletActive
          )}
          key={i}
          onClick={() => onClick(i)}
        />
      ))}
    </div>
  );
};

import styles from './styleHorizontal.scss';
import React, { MouseEventHandler } from 'react';
import { HasTitleAndUrl, ImageCardContent } from '../../../generated/Common';
import { OptionalButton } from '../../inline/button/OptionalButton';
import { clsx, Picture, PoorText, RichText } from '2mr-cms-frontend';

type Props = ImageCardContent & {
  link?: HasTitleAndUrl;
  onClick?: MouseEventHandler;
};
export const ImageCardHorizontal = (props: Props) => {
  const inner = (
    <>
      <div className={styles.cardImageContainer}>
        <Picture
          aria-hidden={true}
          content={props.image}
          className={styles.cardImage}
        />
      </div>
      <div className={styles.cardText}>
        <div className={styles.cardTitle}>
          <PoorText content={props.title} />
        </div>
        <RichText
          element={'div'}
          className={styles.cardDesc}
          content={props.description}
        />
        <OptionalButton style={'air'} link={props.link} color={'black'} />
      </div>
    </>
  );
  if (props.onClick) {
    return (
      <div
        className={clsx(styles.imageCard, styles.cardHoverable)}
        onClick={props.onClick}
      >
        {inner}
      </div>
    );
  }
  return <div className={styles.imageCard}>{inner}</div>;
};

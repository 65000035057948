import styles from './style.scss';
import React from 'react';
import { PoorTextContent, RichTextContent } from '../../../generated/StdLib';
import { clsx, PoorText, RichText } from '2mr-cms-frontend';

type Props = React.PropsWithChildren & {
  className?: string;
  title: PoorTextContent;
  description?: RichTextContent;
};
export const TitleDescription = (props: Props) => {
  return (
    <>
      <h2 className={styles.title}>
        <PoorText content={props.title} />
      </h2>
      <RichText
        element={'div'}
        className={clsx(styles.description, props.className)}
        content={props.description}
      />
    </>
  );
};

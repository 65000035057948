import React from 'react';

import { CharactersContent } from '../generated/Characters';
import { CharacterBlocks } from '../component/pages/characters/characterBlock/CharacterBlock';
import { Header } from '../component/general/header/Header';

type Props = {
  content: CharactersContent;
};

export const CharactersPage = ({ content }: Props) => {
  return (
    <>
      <Header {...content.header} />
      {CharacterBlocks(content.charactersList)}
    </>
  );
};

interface GtmArgs {
  id: string;
  dataLayerName: string;
}

interface IGoogleTags {
  iframe: string;
  script: string;
}

export const initAnal = (gtmId: string) => {
  const domain = window.location.hostname || window.location.host;
  if (domain.includes('localhost') || domain.includes('127.0.0.1')) return;
  GoogleTagManager.initialize({ id: gtmId, dataLayerName: 'dataLayer' });
};

const GoogleTags = ({ id, dataLayerName }: GtmArgs): IGoogleTags | void => {
  if (!id) return console.warn('[GTM-report]', 'GTM Id is required');

  const iframe = `
      <iframe src="https://www.googletagmanager.com/ns.html?id=${id}"
        height="0" width="0" style="display:none;visibility:hidden"></iframe>`;

  const script = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','${dataLayerName}','${id}');`;

  return { iframe, script };
};

export const GoogleTagManager = {
  gtm: function (args: GtmArgs) {
    const snippets = GoogleTags(args);
    if (!snippets) return;

    const noScript = () => {
      const noscript = document.createElement('noscript');
      noscript.innerHTML = snippets.iframe;
      return noscript;
    };

    const script = () => {
      const script = document.createElement('script');
      script.innerHTML = snippets.script;
      return script;
    };

    return { noScript, script };
  },
  initialize: function ({ id, dataLayerName = 'dataLayer' }: GtmArgs) {
    const gtm = this.gtm({ id, dataLayerName });
    if (!gtm) return;
    document.head.insertBefore(gtm.script(), document.head.childNodes[0]);
    document.body.insertBefore(gtm.noScript(), document.body.childNodes[0]);
  },
};

import styles from './style.scss';
import React from 'react';
import {
  ImageCardContent,
  ThreeImageCardsCardContent,
} from '../../../generated/Common';
import { Picture, PoorText, RichText } from '2mr-cms-frontend';

export const ImageCard = (props: ImageCardContent) => {
  return (
    <div className={styles.imageCard}>
      <Picture
        content={props.image}
        aria-hidden={true}
        className={styles.cardImage}
      />
      <div className={styles.cardTitle}>
        <PoorText content={props.title} />
      </div>
      <RichText
        element={'div'}
        className={styles.cardDesc}
        content={props.description}
      />
    </div>
  );
};

export const ImageCard2 = (props: ImageCardContent) => {
  return (
    <div className={styles.imageCard2}>
      <div className={styles.imageWrapper}>
        <Picture
          aria-hidden={true}
          content={props.image}
          className={styles.cardImage2}
        />
      </div>
      <div className={styles.textWrapper}>
        <div className={styles.cardTitle2}>
          <PoorText content={props.title} />
        </div>
        <RichText
          element={'div'}
          className={styles.cardDesc2}
          content={props.description}
        />
      </div>
    </div>
  );
};
export const ImageCard4 = (props: ThreeImageCardsCardContent) => {
  return (
    <div className={styles.imageCard2}>
      <div className={styles.imageWrapper4}>
        <Picture
          aria-hidden={true}
          content={props.image}
          className={styles.cardImage4}
        />
      </div>
      <div className={styles.textWrapper4}>
        {(props.tag || []).map((t, i) => {
          return (
            <div key={i} className={styles.cardTag4}>
              <PoorText content={t} />
            </div>
          );
        })}
        <div className={styles.cardTitle4}>
          <PoorText content={props.title} />
        </div>
        <RichText
          element={'div'}
          className={styles.cardDesc4}
          content={props.description}
        />
      </div>
    </div>
  );
};

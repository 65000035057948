import React from 'react';
import styles from './style.scss';
import { TitleDescription } from '../../../general/titleDescription/TitleDescription';
import { Button } from '../../../inline/button/Button';
import { FullWidthBlock } from '../../../general/fullWidthBlock/FullWidthBlock';
import { PoorText } from '2mr-cms-frontend';
import { PromoSlicedBlock } from './sliced/PromoSlicedBlock';
import { PromoWhatIsContent } from '../../../../generated/Promo';

export const PromoWhatIs = ({
  image,
  title,
  link,
  description,
  id,
}: PromoWhatIsContent) => {
  return (
    <FullWidthBlock bg={'transparent'} id={id}>
      <PromoSlicedBlock background={image}>
        <div className={styles.text}>
          <TitleDescription title={title} description={description} />
          {link && (
            <Button
              className={styles.btn}
              style={'air'}
              color={'white'}
              href={link.url}
            >
              <PoorText content={link.title} />
            </Button>
          )}
        </div>
      </PromoSlicedBlock>
    </FullWidthBlock>
  );
};

import React from 'react';

import styles from './style.scss';
import {
  ForSurvivorContent,
  SurvivorItemsContent,
} from '../../../../generated/EventContent';
import { EventRole } from '../eventRole/EventRole';
import { FlipCard } from '../../../general/flipCard/FlipCard';
import { FullWidthBlock } from '../../../general/fullWidthBlock/FullWidthBlock';
import { Rating } from '../../../inline/rating/Rating';
import { clsx, Picture, PoorText, RichText } from '2mr-cms-frontend';
import { ImageContent, PoorTextContent } from '../../../../generated/StdLib';

export const EventSurvivorBlock = (props: ForSurvivorContent) => {
  return (
    <>
      <EventRole {...props} />
      <FullWidthBlock bg='transparent'>
        <EventSurvivorGrid
          cards={props.grid}
          levels={props.levels}
          icon={props.levelsIcons}
        />
      </FullWidthBlock>
    </>
  );
};

interface Props {
  cards: SurvivorItemsContent[];
  levels: PoorTextContent[];
  icon: ImageContent;
  offset?: number;
  className?: string;
}

export const EventSurvivorGrid = ({
  cards,
  levels,
  icon,
  offset = -60,
  className,
}: Props) => (
  <div
    className={clsx(styles.grid, className)}
    style={{
      transform: `translateY(${offset}px)`,
    }}
  >
    {cards.map((item, i) => (
      <FlipCard
        key={i}
        classname={styles.flipcard}
        top={
          <div className={styles.top}>
            <div className={styles.imgContainer}>
              <Picture
                aria-hidden={true}
                className={styles.image}
                content={item.image}
              />
            </div>
            <div className={styles.cardTitleTop}>
              <div className={styles.cardTitleTopText}>
                <PoorText content={item.title} />
              </div>
              <Rating
                value={item.level}
                max={levels.length}
                iconUrl={icon}
                label={levels[item.level - 1]}
              />
            </div>
          </div>
        }
        bottom={
          <div className={styles.bottom}>
            <div aria-hidden={true} className={styles.cardTitleBottom}>
              <PoorText content={item.title} />
              <Rating
                className={styles.ratingBottom}
                value={item.level}
                max={levels.length}
                iconUrl={icon}
                label={levels[item.level - 1]}
              />
            </div>
            <RichText
              element={'div'}
              className={styles.cardDescBottom}
              content={item.description}
            />
          </div>
        }
      />
    ))}
  </div>
);

import { ThreeImageCardsContent } from './Common';

import { typeDefiner, JsonObjectType, optional, svgOf } from '2mr-cms-typegen';

const defineType = typeDefiner('About.ts');

export const HistoryGridItemContent: JsonObjectType = {
  title: 'text',
  description: 'richText',
  image: 'image',
  tag: 'text',
};
defineType(HistoryGridItemContent, 'HistoryGridItemContent');

export const HistoryGridContent: JsonObjectType = {
  backgroundImageTop: 'image',
  backgroundImageBottom: 'image',
  items: [HistoryGridItemContent],
};

defineType(HistoryGridContent, 'HistoryGridContent');

const TableHeaders: JsonObjectType = {
  locationHeader: 'text',
  outbreakIcon: 'image',
  outbreakHeader: 'text',
  survivorsIcon: 'image',
  survivorsHeader: 'text',
};
export const InteractiveMapItem: JsonObjectType = {
  country: 'text',
  city: 'text',
  image: 'image',
  events: [
    {
      outbreakBegin: 'date',
      outbreakEnd: optional('date'),
      survivors: 'text',
    },
  ],
};
defineType(InteractiveMapItem, 'InteractiveMapItem');

export const InteractiveMapBlock: JsonObjectType = {
  title: 'text',
  subTitle: 'richText',
  superTitle: 'richText',
  tableHeaders: TableHeaders,
  cursor: optional('image'),
  closeIcon: 'image',
  map: svgOf([InteractiveMapItem]),
};
defineType(InteractiveMapBlock, 'InteractiveMapBlock');

export const AboutContent: JsonObjectType = {
  interactiveMap: InteractiveMapBlock,
  historyGrid: HistoryGridContent,
  warPlan: ThreeImageCardsContent,
  ourEvolution: ThreeImageCardsContent,
};
defineType(AboutContent, 'AboutContent');

export const importAboutContent = () => {};

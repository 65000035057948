import React from 'react';
import styles from './style.scss';
import { ImageContent } from '../../../../../generated/StdLib';

interface Props {
  background: ImageContent;
  children: React.ReactNode;
}

export const PromoSlicedBlock = ({ background, children }: Props) => (
  <div className={styles.promoSliced}>
    <div className={styles.content}>{children}</div>
    <div className={styles.bg}>
      <div className={styles.imageWrapper}>
        <img className={styles.image} src={background.value} />
      </div>
      <div className={styles.bgCover} />
    </div>
    <div className={styles.contentMobileWrapper}>
      <div className={styles.contentMobile}>{children}</div>
    </div>
  </div>
);

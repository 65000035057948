import styles from './style3.scss';
import React from 'react';
import { ImageCardContent } from '../../../generated/Common';
import { Picture, PoorText, RichText } from '2mr-cms-frontend';

export const ImageCard3 = (props: ImageCardContent) => {
  return (
    <div className={styles.imageCard}>
      <div className={styles.cardText}>
        <div className={styles.cardTitle}>
          <PoorText content={props.title} />
        </div>
        <RichText
          element={'div'}
          className={styles.cardDesc}
          content={props.description}
        />
      </div>

      <Picture
        aria-hidden={true}
        content={props.image}
        className={styles.cardImage}
      />
    </div>
  );
};

import React from 'react';
import styles from './style.scss';
import { FeaturedSlider } from '../slider/FeaturedSlider';
import { InfectedWorldwideContent } from '../../../generated/Home';
import { TitleDescription } from '../titleDescription/TitleDescription';

export const InfectedWorldwide = (props: InfectedWorldwideContent) => {
  if (!props.slides || props.slides.length === 0) return null;

  return (
    <div className={styles.featured}>
      <div className={styles.titleWrapper}>
        <TitleDescription title={props.title} description={props.description} />
      </div>
      <FeaturedSlider slides={props.slides} />
    </div>
  );
};

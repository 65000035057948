import styles from './mapDataCells.scss';
import React from 'react';
import { formatDateMonthYear } from '../../../utils/dateTime';
import { ImageContent, PoorTextContent } from '../../../generated/StdLib';
import { Picture, PoorText } from '2mr-cms-frontend';

type HeaderWithIconProps = {
  text: PoorTextContent;
  icon?: ImageContent;
};
export const HeaderWithIcon = (props: HeaderWithIconProps) => {
  return (
    <span className={styles.headerCell}>
      {props.icon ? (
        <Picture
          aria-hidden={true}
          className={styles.icon}
          content={props.icon}
        />
      ) : null}
      <PoorText content={props.text} />
    </span>
  );
};

type DateCellProps = {
  from: number;
  to?: number;
};
export const DateCell = (props: DateCellProps) => {
  if (!props.to) {
    return <>{formatDateMonthYear(props.from)}</>;
  }
  return (
    <>
      <span className={styles.dateItem}>
        From {formatDateMonthYear(props.from)}
      </span>
      <span> </span>
      <span className={styles.dateItemTo}>
        to {formatDateMonthYear(props.to)}
      </span>
    </>
  );
};
type LocationCellProps = {
  country: PoorTextContent;
  city: PoorTextContent;
};
export const LocationCell = (event: LocationCellProps) => {
  return (
    <div className={styles.locationCell}>
      <div className={styles.locationCountry}>
        <PoorText content={event.country} />
      </div>
      <div className={styles.locationCity}>
        <PoorText content={event.city} />
      </div>
    </div>
  );
};

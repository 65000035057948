import styles from './mapDataTableMobile.scss';
import React from 'react';
import { InteractiveMapBlock } from '../../../generated/About';
import { DataTable } from '../dataTable/DataTable';
import { FullWidthBlock } from '../fullWidthBlock/FullWidthBlock';
import { DateCell, HeaderWithIcon, LocationCell } from './MapDataCells';
import { PoorText } from '2mr-cms-frontend';

type Props = InteractiveMapBlock;
export const MapDataTableMobile = (props: Props) => {
  const data = props.map.data;
  const headerData = props.tableHeaders;

  const arr = Object.values(data)
    .flat(1)
    .map((card) => {
      const { events, ...rest } = card;
      return events.map((event) => {
        return {
          ...event,
          ...rest,
        };
      });
    })
    .flat(1);

  if (arr.length === 0) {
    return null;
  }

  const sorted = arr.sort((a, b) => {
    return a.outbreakBegin - b.outbreakBegin;
  });

  const rows = sorted.map((event) => {
    return [
      <LocationCell {...event} />,
      <DateCell from={event.outbreakBegin} to={event.outbreakEnd} />,
      <PoorText content={event.survivors} />,
    ];
  });

  const header = [
    <HeaderWithIcon text={headerData.locationHeader} />,
    <HeaderWithIcon
      text={headerData.outbreakHeader}
      icon={headerData.outbreakIcon}
    />,
    <HeaderWithIcon
      text={headerData.survivorsHeader}
      icon={headerData.survivorsIcon}
    />,
  ];

  return (
    <FullWidthBlock bg={'black'} className={styles.fullWidth}>
      <div className={styles.container}>
        <DataTable className={styles.table} header={header} rows={rows} />
      </div>
    </FullWidthBlock>
  );
};

import styles from './style.scss';
import React, { ReactNode } from 'react';
import { clsx } from '2mr-cms-frontend';

type DataTableProps = {
  header: ReactNode[];
  rows: ReactNode[][];
  className?: string;
};
export const DataTable = (props: DataTableProps) => {
  const head = props.header.map((th, i) => {
    return <th key={i}>{th}</th>;
  });
  const rows = props.rows.map((row, i) => {
    return (
      <tr key={i}>
        {row.map((cell, i) => {
          return <td key={i}>{cell}</td>;
        })}
      </tr>
    );
  });
  return (
    <table className={clsx(props.className, styles.table)}>
      <thead>
        <tr>{head}</tr>
      </thead>
      <tbody>{rows}</tbody>
    </table>
  );
};

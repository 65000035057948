import styles from './style.scss';
import React from 'react';
import {
  PackageContent,
  PackagesBlockContent,
} from '../../../../generated/PricingContent';
import { FullWidthBlock } from '../../../general/fullWidthBlock/FullWidthBlock';
import { TitleDescription } from '../../../general/titleDescription/TitleDescription';
import { SpecialTag } from '../../../inline/specialTag/SpecialTag';
import { Picture, PoorText, RichText } from '2mr-cms-frontend';

type Props = PackagesBlockContent;
export const PackagesBlock = (props: Props) => {
  const cards = props.packages.map((p, i) => {
    return <PackageCard {...p} key={i} />;
  });
  return (
    <FullWidthBlock bg={'gray-light'}>
      <div className={styles.packagesBlock}>
        <TitleDescription title={props.title} description={props.description} />
        <div className={styles.grid}>{cards}</div>
      </div>
    </FullWidthBlock>
  );
};
export const PackageCard = (props: PackageContent) => {
  return (
    <a className={styles.card} href={props.url}>
      <SpecialTag tag={props.tag} />
      <span className={styles.main}>
        <Picture
          aria-hidden={true}
          className={styles.img}
          content={props.image}
        />
        <span className={styles.crossedPrice}>
          <PoorText content={props.crossedPrice} />
        </span>
        <span className={styles.price}>
          <span className={styles.priceBig}>
            <PoorText content={props.price.bigText} />
          </span>
          <span className={styles.priceSmall}>
            <PoorText content={props.price.smallText} />
          </span>
        </span>
        <span className={styles.title}>
          <PoorText content={props.title} />
        </span>
        <RichText
          element={'span'}
          content={props.description}
          className={styles.desc}
        />
      </span>
      <span className={styles.bottomBtn}>
        <PoorText content={props.buttonTitle} />
      </span>
    </a>
  );
};

import React, { useEffect, useState } from 'react';
import { NEWS_URL } from '../../../data/Urls';
import { RecentNewsBlockContent } from '../../../generated/Home';
import {
  RecentNewsBlock,
  RecentNewsBlockTheme,
} from '../recentNewsBlock/RecentNewsBlock';
import { NewsArticlePreviewContent } from '../../../generated/NewsContent';
import { Button } from '../../inline/button/Button';
import { fetchNewsList } from '../../../utils/fetch';
import { useParams } from 'react-router-dom';
import { NewsArticlePreviewContentDefinition } from '../../../types/News';
import { PoorText, prepareContent } from '2mr-cms-frontend';

const DEFAULT_PAGE_SIZE = 4;
type Props = RecentNewsBlockContent & {
  showPerPage?: number;
  hideBtn?: boolean;
  theme?: RecentNewsBlockTheme;
};

export function prepareArticlePreview(content: NewsArticlePreviewContent) {
  return prepareContent(
    content,
    'news',
    '.' + (content as any).__index,
    NewsArticlePreviewContentDefinition
  );
}

export const RecentNewsBlockSmart = (props: Props) => {
  const [list, setList] = useState<NewsArticlePreviewContent[]>([]);
  const { slug } = useParams();
  const showPerPage = props.showPerPage || DEFAULT_PAGE_SIZE;

  useEffect(() => {
    fetchNewsList(0, showPerPage + 1)
      .then((r: NewsArticlePreviewContent[]) => {
        const filteredNews = r
          .filter((n) => {
            return n.slug !== slug;
          })
          .slice(0, showPerPage);
        const prepared = filteredNews.map((v) => prepareArticlePreview(v));
        setList(prepared);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [slug]);

  const btn = props.hideBtn ? null : (
    <Button href={props.buttonLink.url || NEWS_URL} style={'air'}>
      {props.buttonLink.label ? (
        <PoorText content={props.buttonLink.label} />
      ) : (
        'See All News'
      )}
    </Button>
  );
  return (
    <RecentNewsBlock
      theme={props.theme}
      title={props.title}
      newsList={list}
      buttonLink={btn}
    />
  );
};

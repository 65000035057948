import React from 'react';
import styles from './style.scss';
import { imgUrlCssVar } from '../../../../utils/CssVar';
import { Button } from '../../../inline/button/Button';
import {
  getShareOnFacebookLink,
  getShareOnTwitterLink,
} from '../../../../utils/socialsShare';
import { NewsArticleContent } from '../../../../generated/NewsContent';
import { LayoutComponent } from '../../../general/layoutComponent/LayoutComponent';
import { PoorText, useContentFromCMSRef } from '2mr-cms-frontend';

type Props = NewsArticleContent & {
  backButton?: {
    label?: string;
    url: string;
  };
};

const DEFAULT_BACK_BUTTON_LABEL = 'Back';

export const Article = (props: Props) => {
  const { ref } = useContentFromCMSRef(props.author.imageUrl);
  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.articleBox}>
          <h1 className={styles.title}>
            <PoorText content={props.title} />
          </h1>
          <hr />
          <div className={styles.authorContainer}>
            <div
              className={styles.authorImage}
              ref={ref}
              style={imgUrlCssVar(
                '--background-image-url',
                props.author.imageUrl.value
              )}
            />
            <div className={styles.authorName}>
              <PoorText content={props.author.name} />
            </div>
            <div className={styles.authorDate}>
              <PoorText content={props.date} /> -{' '}
              <PoorText content={props.readingTime} />
            </div>
          </div>
          <div className={styles.articleBody}>
            <LayoutComponent layout={props.body} />
          </div>
          <div className={styles.controls}>
            <div className={styles.shareButtons}>
              <Button
                className={styles.shareBtn}
                style={'air'}
                takeAllWidth={false}
                href={getShareOnFacebookLink(window.location.toString())}
              >
                Share on Facebook <span className={styles.arrow}></span>
              </Button>
              <Button
                className={styles.shareBtn}
                style={'air'}
                takeAllWidth={false}
                href={getShareOnTwitterLink(window.location.toString())}
              >
                Share on Twitter <span className={styles.arrow}></span>
              </Button>
            </div>
            {props.backButton ? (
              <Button
                className={styles.backBtn}
                style={'air'}
                takeAllWidth={false}
                href={props.backButton.url}
              >
                {props.backButton.label || DEFAULT_BACK_BUTTON_LABEL}
              </Button>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};
